import React from "react";
import ApexCharts from "react-apexcharts";
import { useTheme } from "@mui/material";

const SingleLineChart = ({ data, lineColor, field = "date" }) => {
  const key = !field ? "date" : field;
  const theme = useTheme();

  // Prepare the data for the chart
  const chartData = {
    series: [
      {
        name: "Count",
        data: data.map((item) => item.count),
      },
    ],
    options: {
      chart: {
        type: "line",
        height: 350,
        toolbar: { show: false }, // No toolbar (like Chart.js)
        zoom: { enabled: false }, // Disable zooming if not needed
      },
      stroke: {
        curve: "smooth", // Makes the line smoother (similar to tension in Chart.js)
        width: 2,
        colors: [lineColor || theme.palette.primary.main],
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          shadeIntensity: 1,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100],
        },
      },
      xaxis: {
        categories: data.map((item) => item[key]),
        labels: {
          style: {
            colors: theme.palette.text.primary,
            fontSize: "14px",
            fontWeight: 600, // Larger and bold labels
          },
        },
        tooltip: {
          enabled: false, // Disable tooltip for the x-axis categories
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: theme.palette.text.primary,
            fontSize: "14px",
            fontWeight: 600, // Larger and bold labels
          },
        },
      },
      tooltip: {
        enabled: true,
        shared: true,
        intersect: false,
        theme: "dark",
        style: {
          fontSize: "12px",
        },
        x: {
          formatter: (value) => value, // Formatter for x value in tooltip (same as Chart.js)
        },
        y: {
          formatter: (value) => value, // Formatter for y value in tooltip
        },
      },
      legend: {
        show: false, // Hide legend
      },
      grid: {
        show: false, // Remove grid lines
      },
    },
  };

  return (
    <div>
      <ApexCharts
        options={chartData.options}
        series={chartData.series}
        type="line"
        height={350}
      />
    </div>
  );
};

export default SingleLineChart;
