import React, { useState } from "react";
import {
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Typography,
  IconButton,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Checkbox,
  Menu,
  MenuItem,
  Box,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import axios from "../../api/axiosConfig";
import { toast } from "react-toastify";
import Loader from "../../components/Loader/Loader"; // Import your loader component
import { FaEnvelope } from "react-icons/fa";

const NotificationCards = ({
  notificationData,
  handleInsertRowButton,
  handleEditRowButton,
  setnotificationID,
}) => {
  const [loading, setLoading] = useState(false); // State for loading
  const [profileDialogOpen, setProfileDialogOpen] = useState(false);
  const [notificationDialogOpen, setNotificationDialogOpen] = useState(false);
  const [selectedProfiles, setSelectedProfiles] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedNotificationId, setSelectedNotificationId] = useState(null); // Track selected notification ID
  const [newAnchorEl, setNewAnchorEl] = useState(null);
  const [showButtons, setShowButtons] = useState(false);
  const [selectedNotifProfileId, setselectedNotifProfileId] = useState(null);

  const handleProfileDialogOpen = (profiles) => {
    setSelectedProfiles(profiles);
    setProfileDialogOpen(true);
  };

  const handleProfileDialogClose = () => {
    setProfileDialogOpen(false);
    setShowButtons(false);
    setSelectedNotificationId(null);
  };

  const handleNotificationDialogOpen = () => {
    setNotificationDialogOpen(true);
  };

  const handleNotificationDialogClose = () => {
    setNotificationDialogOpen(false);
    setSelectedNotificationId(null);
  };

  const handleOptionsClick = (event, notification) => {
    setAnchorEl(event.currentTarget);
    setSelectedNotificationId(notification.id); // Store notification ID
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedNotificationId(null);
  };

  const handleNewOptionsClick = (event, notifprofile) => {
    setNewAnchorEl(event.currentTarget);
    setselectedNotifProfileId(notifprofile.id); // Store notification ID
  };

  const handleNewMenuClose = () => {
    setNewAnchorEl(null);
    setselectedNotifProfileId(null);
  };

  const deleteNotification = async (id) => {
    try {
      const isConfirmed = window.confirm(
        `Are you sure you want to delete this notification?`
      );
      if (isConfirmed) {
        setLoading(true);
        const response = await axios.delete(
          `/news-notifs/delete-notification/${id}`
        );
        toast.success(response.data.status);
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.message || "Error deleting notification"
      );
      console.error("Error deleting notification:", error);
    } finally {
      setLoading(false);
    }
  };

  const emailNotification = async () => {
    console.log(selectedNotificationId);
    try {
      const isConfirmed = window.confirm(
        `Are you sure you want to send this notification via email?`
      );
      if (isConfirmed) {
        setLoading(true);
        const response = await axios.get(`/news-notifs/notify-email/`, {
          params: { id: selectedNotificationId },
        });
        toast.success(response.data.status);
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.message || "Error sending notification via email"
      );
      console.error("Error sending notification via email:", error);
    } finally {
      setLoading(false);
      setSelectedNotificationId(null);
      handleNotificationDialogClose();
    }
  };

  const deleteNotifProfile = async (id) => {
    try {
      const isConfirmed = window.confirm(
        `Are you sure you want to delete this notification attachment?`
      );
      if (isConfirmed) {
        setLoading(true);
        const response = await axios.delete(
          `/news-notifs/delete-notification-profile/${id}`
        );
        toast.success(response.data.status);
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.message || "Error deleting notification profile"
      );
      console.error("Error deleting notification profile:", error);
    } finally {
      setLoading(false);
      setProfileDialogOpen(false);
      setSelectedNotificationId(null);
      setselectedNotifProfileId(null);
    }
  };

  return (
    <>
      {" "}
      {loading && (
        <div className="overlay">
          <Loader />
        </div>
      )}
      <Grid container spacing={2}>
        {notificationData.map((notification) => (
          <Grid item xs={12} sm={6} md={4} key={notification.id}>
            <Box sx={{ position: "relative", maxWidth: 400 }}>
              <Card>
                <CardMedia
                  component="img"
                  height="140"
                  image={notification.image || "/news.png"}
                  alt={notification.title}
                />
                <div
                  style={{
                    position: "absolute",
                    top: 10,
                    left: 10,
                    backgroundColor: "greenyellow",
                    padding: "5px",
                  }}
                >
                  <Typography variant="h6">{notification.priority}</Typography>
                </div>
                <IconButton
                  style={{
                    position: "absolute",
                    top: 10,
                    right: 10,
                    color: "greenyellow",
                  }}
                  onClick={(e) => handleOptionsClick(e, notification)}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  <MenuItem
                    onClick={() => {
                      handleEditRowButton(
                        "notification",
                        selectedNotificationId
                      );
                      setAnchorEl(null);
                      handleMenuClose();
                    }}
                  >
                    Edit
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      deleteNotification(selectedNotificationId);
                      setAnchorEl(null);
                      handleMenuClose();
                    }}
                  >
                    Delete
                  </MenuItem>
                </Menu>
                <CardContent>
                  <Typography variant="body2" color="text.secondary">
                    {" "}
                    ({notification.date})
                  </Typography>
                  <Typography gutterBottom variant="h5">
                    <a
                      href={
                        notification.url
                          ? notification.url
                          : "https://assessworth.com"
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      {notification.title}
                    </a>
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {notification.content}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    style={{ backgroundColor: "black", color: "white" }}
                    onClick={() => {
                      setSelectedNotificationId(notification.id);
                      handleProfileDialogOpen(
                        notification.notification_profiles
                      );
                    }}
                  >
                    View Profiles
                  </Button>
                  <Button
                    onClick={() => {
                      handleNotificationDialogOpen();
                      setSelectedNotificationId(notification.id);
                    }}
                    style={{ backgroundColor: "black", color: "white" }}
                  >
                    Send Notifications
                  </Button>
                </CardActions>
              </Card>
            </Box>
          </Grid>
        ))}

        {/* Profile Dialog */}
        <Dialog open={profileDialogOpen} onClose={handleProfileDialogClose}>
          <DialogTitle>Profiles</DialogTitle>
          <DialogContent>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Icon</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Reason</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedProfiles.map((profile) => (
                  <TableRow key={profile.id}>
                    <TableCell>
                      <img
                        src={profile.profile_profile.icon || "/user.png"}
                        alt={profile.profile_profile.name}
                        style={{ width: 40, height: 40, borderRadius: "50%" }} // Example styling for the icon
                      />
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1">
                        {profile.profile_profile.name}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {profile.profile_profile.email}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1">
                        {profile.description}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Checkbox checked={profile.email === 1} disabled={true} />
                    </TableCell>
                    <TableCell>
                      <IconButton
                        onClick={(e) => handleNewOptionsClick(e, profile)}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        anchorEl={newAnchorEl}
                        open={Boolean(newAnchorEl)}
                        onClose={() => handleNewMenuClose()}
                      >
                        <MenuItem
                          onClick={() => {
                            // Add your Edit function here
                            handleEditRowButton(
                              "spec_notification_profile",
                              selectedNotifProfileId
                            );
                            handleNewMenuClose();
                            handleProfileDialogClose();
                          }}
                        >
                          Edit
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            // Add your Delete function here
                            deleteNotifProfile(selectedNotifProfileId);
                            setNewAnchorEl(null);
                          }}
                        >
                          Delete
                        </MenuItem>
                      </Menu>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Button
              style={{
                backgroundColor: "black",
                color: "white",
                marginTop: "20px",
              }}
              onClick={() => setShowButtons(showButtons ? false : true)}
            >
              Add Profile
            </Button>
            {showButtons && (
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "10px",
                  marginTop: "10px",
                }}
              >
                <Button
                  style={{ backgroundColor: "black", color: "white" }}
                  onClick={() => {
                    setnotificationID(selectedNotificationId);
                    handleInsertRowButton("notification_profile");
                    setShowButtons(false);
                    handleProfileDialogClose();
                  }}
                >
                  Add Single
                </Button>
                <Button
                  style={{ backgroundColor: "black", color: "white" }}
                  onClick={() => {
                    setnotificationID(selectedNotificationId);
                    setShowButtons(false);
                    handleInsertRowButton("notification_asset");
                    handleProfileDialogClose();
                  }}
                >
                  By Asset
                </Button>
                <Button
                  style={{ backgroundColor: "black", color: "white" }}
                  onClick={() => {
                    setShowButtons(false);
                    setnotificationID(selectedNotificationId);
                    handleInsertRowButton("notification_industry");
                    handleProfileDialogClose();
                  }}
                >
                  By Industry
                </Button>
                <Button
                  style={{ backgroundColor: "black", color: "white" }}
                  onClick={() => {
                    setnotificationID(selectedNotificationId);
                    setShowButtons(false);
                    handleInsertRowButton("notification_country");
                    handleProfileDialogClose();
                  }}
                >
                  By Country
                </Button>
                <Button
                  style={{ backgroundColor: "black", color: "white" }}
                  onClick={() => {
                    setnotificationID(selectedNotificationId);
                    setShowButtons(false);
                    handleInsertRowButton("notification_investor_type");
                    handleProfileDialogClose();
                  }}
                >
                  By Investor Type
                </Button>
              </Box>
            )}
          </DialogContent>
        </Dialog>

        {/* Send Notification Dialog */}
        <Dialog
          open={notificationDialogOpen}
          onClose={handleNotificationDialogClose}
        >
          <DialogTitle>Send Notifications</DialogTitle>
          <DialogContent>
            <Button
              style={{ backgroundColor: "black", color: "white" }}
              onClick={() => {
                emailNotification();
              }}
            >
              <FaEnvelope />
            </Button>
          </DialogContent>
        </Dialog>
      </Grid>
    </>
  );
};

export default NotificationCards;
