// import axios from "../../api/axiosConfig";

// let columnsMap = {};

// const fetchColumnsMap = async () => {
//   try {
//     const response = await axios.get("/insert/get-table-columns");
//     columnsMap = response.data.data;
//   } catch (error) {
//     console.error("Error fetching columns map:", error);
//   }
// };

// fetchColumnsMap();

// Define columns based on subjectTableSelection
const columnsMap = {
  asset_type: ["name"],
  blog: [
    "blog_category",
    "name",
    "author",
    "date",
    "cover",
    "url",
    "description",
  ],
  blog_category: ["name", "description"],
  broker: ["name", "country", "icon", "website", "description"],
  company_information: ["symbol", "name", "sic_code", "icon", "description"],
  country: ["name", "abv", "icon"],
  currency: ["name", "abv", "country"],
  daily_prices: ["date", "symbol", "price"],
  dividend: [
    "symbol",
    "declaration_date",
    "ex_dividend_date",
    "record_date",
    "pay_date",
    "dividend",
  ],
  education_glossary: ["name", "meaning"],
  education_image: ["icon", "image_url"],
  exchange: ["name", "abv", "country", "icon", "website", "description"],
  financials: [
    "symbol",
    "filling_date",
    "year",
    "period",
    "eps",
    "current_assets",
    "current_liabilities",
    "total_assets",
    "total_liabilities",
    "revenue",
    "net_income_loss",
    "net_cash_flow",
    "market_cap",
    "outstanding_shares",
  ],
  investor_type: ["name", "description"],
  news: ["author", "publish_date", "title", "url", "image", "description"],
  news_symbol: ["news", "symbol", "sentiment", "description"],
  special_news: [
    "author",
    "publish_date",
    "title",
    "url",
    "image",
    "description",
    "symbol",
    "sentiment",
    "reason",
  ],
  stock_split: ["symbol", "date", "split_from", "split_to", "status"],
  news_group: ["news", "country", "sic_code", "exchange"],
  notification: ["date", "title", "url", "image", "content", "priority"],
  notification_profile: ["notification", "profile", "description", "email"],
  spec_notification_profile: ["description", "email"],
  notification_country: ["notification", "country", "description", "email"],
  notification_investor_type: [
    "notification",
    "investor_type",
    "description",
    "email",
  ],
  notification_asset: ["notification", "symbol", "description", "email"],
  notification_industry: ["notification", "sic_code", "description", "email"],
  occupation: ["name"],
  payment_service: ["name", "description"],
  payment_tier: ["name", "duration", "price", "description"],
  payment_tier_service: ["payment_tier", "payment_service"],
  payment_subscription: [
    "profile",
    "payment_tier",
    "status",
    "start_date",
    "end_date",
  ],
  rate_type: ["name", "description"],
  rates: ["rate_type", "country", "date", "rate"],
  recommendation_type: ["name", "description"],
  sic_code: ["sic_code", "office", "industry", "description"],
  symbol: ["name", "asset_type", "exchange", "country"],
  recommendations: ["symbol", "recommendation_type", "description"],
};

const dates = [
  "date",
  "declaration_date",
  "ex_dividend_date",
  "record_date",
  "pay_date",
  "filling_date",
  "submission_date",
  "publish_date",
  "date",
  "dob",
  "start_date",
  "end_date",
];

const numericals = [
  "affected_asset",
  "ratings",
  "year",
  "price",
  "dividend",
  "eps",
  "current_assets",
  "current_liabilities",
  "total_assets",
  "total_liabilities",
  "revenue",
  "net_income_loss",
  "net_cash_flow",
  "market_cap",
  "outstanding_shares",
  "month",
  "quarter",
  "half",
  "year",
];

const tables = [
  "country",
  "sic_code",
  "currency",
  "broker",
  "exchange",
  "asset_type",
  "recommendation_type",
  "rate_type",
  "news",
  "investor_type",
  "payment_service",
  "payment_tier",
  "blog_category",
];

const tableNames = {
  country: "Country",
  sic_code: "Industry",
  currency: "Currency",
  broker: "Brokerage Firm",
  exchange: "Stock Exchange",
  asset_type: "Asset Class",
  recommendation_type: "Recommendation",
  symbol: "Asset Symbol",
  rate_type: "Type of Rate",
  investor_type: "Type of Investor",
  payment_service: "Payment Service",
  payment_tier: "Payment Tier",
  blog_category: "Blog Category",
};

const others = [
  "country",
  "symbol",
  "sic_code",
  "news",
  "currency",
  "broker",
  "exchange",
  "status",
  "type",
  "period",
];

const excludeBulk = [
  "asset_type",
  "investor_type",
  "recommendation_type",
  "rate_type",
];

const recommendation_schema = {
  definitions: {
    dividend:
      "These are stocks that provide regular and usually higher than the average dividend payments.",
    enterprise:
      "These are the stocks of large companies near or at their peak with stable earnings.",
    general:
      "These stocks cuts accross different industries and recommendation types.",
    growth:
      "These are stocks that have proven to give above-average returns when compared with the general market.",
    passive:
      "These stocks are the safest to invest in and require very minimal monitoring by the investor.",
  },
  requirements: {
    dividend: [
      "At most two (2) annual liability increase for the past five years (for safety, very important).",
      "At least two to six percent (2-6%) dividend yield (i.e., dividend price over share price).",
      "Last year dividend price must be greater than the average of the past five years.",
      "Dividend payout ratio (total dividend over net income or dividend over eps) must be thirty to seventy-five percent (30-75%).",
    ],
    enterprise: [
      "TTM P/E ratio (i.e., how much investors are willing to pay per the earnings of the stock) must be less than 10.",
      "Current asset must be greater than current liabilities * 1.5 OR total liabilities.",
      "Past five years EPS must be positive.",
      "The stock gives dividend returns.",
      "Net income growth must be positive for the past five years.",
    ],
    general: [
      "P/E ratio (i.e., how much investors are willing to pay per the earnings of the stock) must be less than 20.",
      "The last profit margin must be greater than 10%.",
      "There must be a consistent revenue growth increase for the past five years.",
      "There must be a consistent profit growth increase for the past five years.",
      "Outstanding shares (i.e., authorized shares not held by the company) must be the same or decreasing for the past five years.",
      "Current assets must be greater than current liabilities.",
      "There must be a consistent free cash flow growth increase for the past five years.",
    ],
    growth: [
      "Add this: The returns must exceed the average returns of the market (country-wide).",
      "There must be a consistent profit margin increase for the past five years.",
      "There must be a consistent revenue growth increase for the past five years.",
      "There must be a consistent profit growth increase for the past five years.",
      "Return of equity (net income / (asset - liability)) must be greater than 1.5.",
      "At most two (2) annual liability increase for the past five years (for safety, very important).",
    ],
    passive: [
      "The semi-deviation must be less than 15 percent.",
      "Revenue must be greater than 100 million or current assets greater than 50 million.",
      "Current assets must exceed current liabilities.",
      "The stock price must have been increasing for the last ten years.",
      "Uninterupted dividend payment for the past five years.",
      "Average recent EPS must be greater than average past EPS.",
      "P/E ratio (i.e., how much investors are willing to pay per the earnings of the stock) must be less than 15.",
    ],
  },
};

export {
  columnsMap,
  dates,
  numericals,
  tables,
  tableNames,
  excludeBulk,
  others,
  recommendation_schema,
};

/*
SELECT COLUMN_NAME, TABLE_NAME
FROM INFORMATION_SCHEMA.COLUMNS
WHERE TABLE_NAME IN (SELECT TABLE_NAME AS name
          FROM INFORMATION_SCHEMA.TABLES
          WHERE TABLE_TYPE = 'BASE TABLE' AND TABLE_SCHEMA= '') -- insert database name here
          AND COLUMN_NAME != 'id'
          AND DATA_TYPE = ''; -- run without this line to get all or run next query
          
SELECT DISTINCT(DATA_TYPE)
FROM INFORMATION_SCHEMA.COLUMNS
WHERE TABLE_NAME IN (SELECT TABLE_NAME AS name
          FROM INFORMATION_SCHEMA.TABLES
          WHERE TABLE_TYPE = 'BASE TABLE' AND TABLE_SCHEMA= '')
          AND COLUMN_NAME != 'id';
          
          
/*
datetime
varchar
int
text
date
decimal
enum
*/
