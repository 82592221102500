import React, { useState } from "react";
import axios from "../../api/axiosConfig";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  CardActions,
  Button,
  Collapse,
  Chip,
  Box,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import { toast } from "react-toastify";
import Loader from "../../components/Loader/Loader"; // Import your loader component
import MoreVertIcon from "@mui/icons-material/MoreVert";

const NewsCard = ({
  newsItem,
  handleInsertRowButton,
  setnewsID,
  handleEditRowButton,
}) => {
  const [showSymbols, setShowSymbols] = useState(false);
  const [loading, setLoading] = useState(false); // State for loading
  const [anchorEl, setAnchorEl] = useState(null); // For the dropdown menu

  const toggleSymbols = () => {
    setShowSymbols(!showSymbols);
  };

  const deleteNews = async (id) => {
    try {
      const isConfirmed = window.confirm(
        `Are you sure you want to delete this news?`
      );
      if (isConfirmed) {
        setLoading(true);
        const response = await axios.delete(`/news-notifs/delete-news/${id}`);
        toast.success(response.data.status);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "Error deleting news");
      console.error("Error deleting news:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {loading && (
        <div className="overlay">
          <Loader />
        </div>
      )}
      <Box sx={{ position: "relative", maxWidth: 400 }}>
        <Card sx={{ position: "relative" }}>
          {/* Options Button */}
          <IconButton
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "greenyellow",
            }}
            onClick={handleMenuClick}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem
              onClick={() => {
                handleEditRowButton("news", newsItem.id);
                setAnchorEl(null);
              }}
            >
              Edit
            </MenuItem>
            <MenuItem
              onClick={() => {
                deleteNews(newsItem.id);
                setAnchorEl(null);
              }}
            >
              Delete
            </MenuItem>
          </Menu>
          {/* Cover Photo */}
          <CardMedia
            sx={{ height: 200 }}
            image={newsItem.image || "/news.png"}
            title={newsItem.title}
          />

          {/* Card Content */}
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              <a
                href={newsItem.url}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                {newsItem.title}
              </a>
              <Typography variant="body2" color="text.secondary">
                {" "}
                ({newsItem.publish_date})
              </Typography>
            </Typography>

            <Typography variant="body2" color="text.secondary">
              {newsItem.description}
            </Typography>

            <Typography
              variant="subtitle2"
              color="text.secondary"
              sx={{ marginTop: 1 }}
            >
              Source: {newsItem.author}
            </Typography>
          </CardContent>

          <CardActions>
            <Button
              variant="contained"
              sx={{ backgroundColor: "black", color: "white" }}
              size="small"
              onClick={toggleSymbols}
            >
              View Symbols
            </Button>
          </CardActions>

          <Collapse in={showSymbols} timeout="auto" unmountOnExit>
            <Box sx={{ padding: 2 }}>
              <Grid container spacing={1} sx={{ flexWrap: "wrap" }}>
                {newsItem.news_symbols.map((symbolItem, index) => (
                  <Grid item xs="auto" key={index}>
                    <SymbolBox symbolItem={symbolItem} />
                  </Grid>
                ))}
              </Grid>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "black",
                  color: "white",
                  marginTop: "10px",
                }}
                size="small"
                onClick={() => {
                  handleInsertRowButton("news_symbol");
                  setnewsID(newsItem.id);
                }}
              >
                Add Symbol
              </Button>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "black",
                  color: "white",
                  marginLeft: "7px",
                  marginTop: "10px",
                }}
                size="small"
                onClick={() => {
                  handleInsertRowButton("news_group");
                  setnewsID(newsItem.id);
                }}
              >
                Add Group
              </Button>
            </Box>
          </Collapse>
        </Card>
      </Box>
    </>
  );
};

const SymbolBox = ({ symbolItem }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [editSentiment, seteditSentiment] = useState(false);
  const [sentimentObject, setsentimentObject] = useState({});
  const [loading, setLoading] = useState(false);

  const getSentimentColor = (sentiment) => {
    switch (sentiment) {
      case "positive":
        return "success";
      case "negative":
        return "error";
      default:
        return "default";
    }
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    seteditSentiment(false);
    setsentimentObject({});
  };

  const editSentimentButton = async (id) => {
    setsentimentObject({ id: id });
  };

  const submitEditSentimentButton = async () => {
    try {
      setLoading(true);
      const response = await axios.patch(`/news-notifs/edit-sentiment`, {
        object: sentimentObject,
      });
      toast.success(response.data.status);
    } catch (error) {
      toast.error(
        error?.response?.data?.message || "Error replacing sentiment"
      );
      console.error("Error inserting value", error);
    } finally {
      symbolItem.sentiment = sentimentObject?.sentiment
        ? sentimentObject?.sentiment
        : symbolItem.sentiment;
      symbolItem.description = sentimentObject?.description
        ? sentimentObject?.description
        : symbolItem.description;
      seteditSentiment(false);
      setsentimentObject({});
      setLoading(false);
    }
  };

  const handleConfirmInsert = (name) => {
    const isConfirmed = window.confirm(
      `Are you sure you want to edit the sentiment details of ${name}?`
    );
    if (isConfirmed) {
      submitEditSentimentButton();
    } else {
      seteditSentiment(false);
    }
  };

  const deleteNewsSymbol = async (id) => {
    try {
      const isConfirmed = window.confirm(
        `Are you sure you want to delete this news sentiment?`
      );
      if (isConfirmed) {
        setLoading(true);
        const response = await axios.delete(
          `/news-notifs/delete-news-symbol/${id}`
        );
        toast.success(response.data.status);
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.message || "Error deleting news sentiment"
      );
      console.error("Error deleting news sentiment:", error);
    } finally {
      setLoading(false);
      setOpenDialog(false);
    }
  };

  return (
    <>
      {loading && (
        <div className="overlay">
          <Loader />
        </div>
      )}
      {/* Symbol Chip */}
      <Box sx={{ margin: "4px" }}>
        <Chip
          label={symbolItem.symbol_symbol.name}
          color={getSentimentColor(symbolItem.sentiment)}
          onClick={handleOpenDialog}
          sx={{ borderRadius: 2, cursor: "pointer" }}
        />
      </Box>

      {/* Popup Dialog */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{symbolItem.symbol_symbol.name}</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            <strong>Sentiment:</strong> {symbolItem.sentiment}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ marginTop: 1 }}
          >
            <strong>Description:</strong> {symbolItem.description}
          </Typography>
          {editSentiment && (
            <>
              <div>
                <select
                  style={{ marginTop: "20px" }}
                  className="form-control"
                  onChange={(e) =>
                    setsentimentObject((prevObject) => ({
                      ...prevObject, // Spread the existing keys of the object
                      sentiment: e.target.value,
                    }))
                  }
                >
                  <option value="">Select the Sentiment</option>
                  <option value="positive">Positive</option>
                  <option value="negative">Negative</option>
                  <option value="neutral">Neutral</option>
                </select>
              </div>
              <div>
                <textarea
                  style={{ marginTop: "10px", width: "100%" }}
                  type="text"
                  placeholder="Sentiment Reason"
                  onChange={(e) =>
                    setsentimentObject((prevObject) => ({
                      ...prevObject, // Spread the existing keys of the object
                      description: e.target.value,
                    }))
                  }
                />
              </div>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "black",
                  color: "white",
                  marginTop: "10px",
                }}
                size="small"
                onClick={() =>
                  handleConfirmInsert(symbolItem.symbol_symbol.name)
                }
              >
                Submit
              </Button>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{ backgroundColor: "black", color: "white" }}
            onClick={() => {
              // Add your edit action here
              seteditSentiment(!editSentiment);
              editSentimentButton(symbolItem.id);
            }}
          >
            Edit
          </Button>
          <Button
            variant="contained"
            sx={{ backgroundColor: "red", color: "white" }}
            onClick={() => deleteNewsSymbol(symbolItem.id)}
          >
            Delete
          </Button>
          <Button
            variant="contained"
            sx={{ backgroundColor: "black", color: "white" }}
            onClick={handleCloseDialog}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default NewsCard;
