// DynamicTable.js
import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Button,
  IconButton,
} from "@mui/material";
import * as XLSX from "xlsx";
import { FaEdit, FaFileExcel, FaFilePdf } from "react-icons/fa"; // Import the icon
import jsPDF from "jspdf";
import "jspdf-autotable"; // Optional: for tables

const DynamicTable = ({ data, handleEditRowButton, selectedTable }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const exportToExcel = () => {
    // Exclude the Edit column from the export
    const exportData = data.map(({ id, ...row }) => row);
    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
    XLSX.writeFile(workbook, "AssessWorth_data_export.xlsx");
  };

  const exportToPDF = () => {
    const exportData = data.map(({ id, ...row }) => row); // Exclude the Edit column
    const doc = new jsPDF();
    doc.text("AssessWorth", 10, 10);

    // Add table data
    doc.autoTable({
      head: [Object.keys(exportData[0])],
      body: exportData.map((row) => Object.values(row)),
    });

    doc.save("AssessWorth_doc_export.pdf");
  };

  if (!data || data.length === 0) return <div>No data available</div>;

  const columns = Object.keys(data[0]);

  return (
    <Paper>
      <div>
        <Button
          onClick={exportToExcel}
          variant="contained"
          color="inherit"
          startIcon={<FaFileExcel style={{ color: "#217346" }} />}
        ></Button>
        <Button
          onClick={exportToPDF}
          variant="contained"
          color="inherit"
          startIcon={<FaFilePdf style={{ color: "#b0251c" }} />}
        ></Button>
      </div>
      <TableContainer
        sx={{
          maxHeight: 440,
          width: "100%", // Set width to 100% of the parent container
          overflowX: "auto", // Enable horizontal scrolling
        }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column}
                  sx={{
                    backgroundColor: "#000000",
                    color: "white",
                    fontWeight: "bold", // Optional: make text bold
                  }}
                >
                  {column}
                </TableCell>
              ))}
              {/* Add column header for the Edit button */}
              {handleEditRowButton && selectedTable && (
                <TableCell
                  sx={{
                    backgroundColor: "#000000",
                    color: "white",
                    fontWeight: "bold",
                  }}
                >
                  Edit
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  {columns.map((column) => (
                    <TableCell
                      key={column}
                      sx={{
                        backgroundColor:
                          row[column] === null || row[column] === ""
                            ? "gray"
                            : row[column] === false
                            ? "red"
                            : row[column] === true
                            ? "green"
                            : "inherit",
                      }}
                    >
                      {row[column]}
                    </TableCell>
                  ))}
                  {/* Add Edit button in each row */}
                  {handleEditRowButton && selectedTable && (
                    <TableCell>
                      <IconButton
                        onClick={() => {
                          handleEditRowButton(selectedTable, row.id);
                        }}
                        size="small"
                        color="primary"
                      >
                        <FaEdit />
                      </IconButton>
                    </TableCell>
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 15]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default DynamicTable;
