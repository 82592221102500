import React, { useState, useEffect } from "react";
import axios from "../api/axiosConfig";
import { Link } from "react-router-dom";
import "./Sidebar.css"; // You can use this to add styles to your sidebar
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToastContainer, toast } from "react-toastify";
import {
  faBars,
  faTimes,
  faHome,
  faTable,
  faPlus,
  faEdit,
  faCheck,
  faSignOutAlt,
  faUser,
  faBell,
  faNewspaper,
  faPen,
  faCreditCard,
  faStamp,
  faBook,
  faChartArea,
} from "@fortawesome/free-solid-svg-icons";
import { signOut } from "aws-amplify/auth";

function Sidebar({ isOpen, toggleSidebar }) {
  const [, setAdminName] = useState("Admin");
  const [hasPermission, sethasPermission] = useState(0);

  useEffect(() => {
    handleFetchAdmin();
  });

  const handleFetchAdmin = async () => {
    try {
      const response = await axios.get(`/admin/get-profile`);
      if (response.status === 200) {
        setAdminName(response.data.data.name);
        sethasPermission(response.data.data.admin_level);
      } else {
        toast.error(`Unexpected response code: ${response.status}`);
      }
    } catch (error) {
      if (error.response?.data?.message)
        toast.error(error.response.data.message);
      console.error("Error fetching data", error);
    }
  };

  const handleSignOut = async () => {
    try {
      await signOut();
      window.location.reload(); // Reload the page to update the authentication state
    } catch (error) {
      console.log("Error signing out: ", error);
    }
  };
  return (
    <div className={`sidebar ${isOpen ? "open" : "closed"}`}>
      <button className="toggle-button" onClick={toggleSidebar}>
        <FontAwesomeIcon icon={isOpen ? faTimes : faBars} />
      </button>
      <ul>
        <li>
          <Link to="/">
            <FontAwesomeIcon icon={faHome} />
            Home
          </Link>
        </li>
        <li>
          <Link to="/Dashboard">
            <FontAwesomeIcon icon={faChartArea} />
            General Dashboard
          </Link>
        </li>
        <li>
          <Link to="/compareTables">
            <FontAwesomeIcon icon={faTable} />
            Compare Tables
          </Link>
        </li>
        <li>
          <Link to="/HandleNulls">
            <FontAwesomeIcon icon={faPlus} />
            Handling Nulls
          </Link>
        </li>
        <li>
          <Link to="/InsertandEdits">
            <FontAwesomeIcon icon={faEdit} />
            Inserts and Edits
          </Link>
        </li>
        <li>
          <Link to="/Recommendations">
            <FontAwesomeIcon icon={faCheck} />
            Recommendations
          </Link>
        </li>
        <li>
          <Link to="/Blogs">
            <FontAwesomeIcon icon={faPen} />
            Blogs
          </Link>
        </li>
        <li>
          <Link to="/Notifications">
            <FontAwesomeIcon icon={faBell} />
            Notifications
          </Link>
        </li>
        <li>
          <Link to="/News">
            <FontAwesomeIcon icon={faNewspaper} />
            News
          </Link>
        </li>
        <li>
          <Link to="/Education">
            <FontAwesomeIcon icon={faBook} />
            Education
          </Link>
        </li>
        {hasPermission > 1 && (
          <>
            <li>
              <Link to="/AdminDashboard">
                <FontAwesomeIcon icon={faUser} />
                Admin Dashboard
              </Link>
            </li>
            <li>
              <Link to="/Subscriptions">
                <FontAwesomeIcon icon={faCreditCard} />
                Subscriptions
              </Link>
            </li>
            <li>
              <Link to="/Actions">
                <FontAwesomeIcon icon={faStamp} />
                Admin Actions
              </Link>
            </li>
          </>
        )}
        <li>
          <button onClick={handleSignOut} className="sign-out-button">
            <FontAwesomeIcon icon={faSignOutAlt} />
            Sign Out
          </button>
        </li>
      </ul>
      <ToastContainer />
    </div>
  );
}

export default Sidebar;
