import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";

const MultiLineChart = ({ lineData, selectedLineValues }) => {
  const [chartOptions, setChartOptions] = useState({});
  const [chartSeries, setChartSeries] = useState([]);

  useEffect(() => {
    const updateChart = () => {
      const categories = lineData.map((data) => data.date);
      const series = selectedLineValues.map((selectedValue) => {
        //const key = selectedValue.replace("Users", "").toLowerCase();
        return {
          name: selectedValue,
          data: lineData.map((data) => data[selectedValue]),
        };
      });

      setChartOptions({
        chart: {
          type: "line",
          height: 350,
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        grid: {
          show: false,
        },
        stroke: {
          curve: "smooth",
        },
        xaxis: {
          categories: categories,
        },
        tooltip: {
          enabled: true,
        },
      });

      setChartSeries(series);
    };

    updateChart();
  }, [lineData, selectedLineValues]);

  return (
    <div>
      <Chart
        options={chartOptions}
        series={chartSeries}
        type="line"
        height={350}
      />
    </div>
  );
};

export default MultiLineChart;
