import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import axios from "../../api/axiosConfig";
import realAxios from "axios";
import { fetchAuthSession } from "aws-amplify/auth";
import Loader from "../../components/Loader/Loader"; // Import your loader component
import {
  Box,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Checkbox,
  ListItemText,
  TextField,
  Button,
} from "@mui/material";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import ProfileSearch from "../../components/Popups/ProfileSearch";
import SingleLineChart from "../../components/Charts/Single Line Chart/SingleLineChart";
import DynamicTable from "../../components/MUI Table/DynamicTable";
import ClusteredBarChart from "./components/ClusteredBarChart";
import { FeatureCards } from "./components/DataCards";

function Features() {
  const [loading, setLoading] = useState(false); // State for loading
  const [cohorts, setCohorts] = useState([]);
  const [selectedCohortPeriod, setSelectedCohortPeriod] = useState("");
  const [selectedGender, setSelectedGender] = useState("all");
  const [selectedPaymentTier, setSelectedPaymentTier] = useState("all");
  const [useCohort, setSelectedUseCohort] = useState("no");
  const [useUser, setUseUser] = useState("no");
  const [selectedPage, setSelectedPage] = useState("all");
  const [selectedDuration, setSelectedDuration] = useState("month");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedCohorts, setSelectedCohorts] = useState([]);
  const [userTable, seUserTable] = useState([]);
  const [otherUserTable, setOtherUserTable] = useState([]);
  const [otherFeatureTable, setOtherFeatureTable] = useState([]);
  const [lineData, setLineData] = useState([]);
  const [featureTable, setFeatureTable] = useState([]);
  const [barData, setBarData] = useState([]);
  const [userActive, setUserActive] = useState(true);
  const [featureActive, setFeatureActive] = useState(true);
  const [cards, setCards] = useState({
    totalCount: 0,
    activeUserCount: 0,
    inactiveUserCount: 0,
    activeUserProportion: 0,
    activeFeatureCount: 0,
    inactiveFeatureCount: 0,
    activeFeatureProportion: 0,
  });
  const pages = [
    "all",
    "landing",
    "profile",
    "portfolio",
    "watchlist",
    "subscription",
    "stock",
    "dashboard",
    "recommendation",
    "education",
    "dividend",
  ];
  const BlackSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "black",
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "black",
    },
  }));

  useEffect(() => {}, []);

  const fetchCohorts = async (period) => {
    try {
      setLoading(true);
      const response = await axios.get("/dashboard/get-cohorts", {
        params: {
          cohortPeriod: period,
        },
      });
      setCohorts(response.data.data);
    } catch (error) {
      handleReset();
      console.error("Error fetching data: ", error);
      if (error.response?.data?.message)
        toast.error(error.response.data.message);
    } finally {
      setLoading(false); // Set loading to false after data is fetched
    }
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const authToken = (await fetchAuthSession()).tokens?.idToken?.toString();
      const response = await realAxios.post(
        "https://07prxr5v1m.execute-api.eu-west-2.amazonaws.com/prod/user-analytics",
        {
          gender: selectedGender,
          payment: selectedPaymentTier,
          cohortPeriod: selectedCohortPeriod,
          selectedCohorts: selectedCohorts,
          page: selectedPage,
          duration: selectedDuration,
          startDate: startDate,
          endDate: endDate,
          userId: selectedUser,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`, // Replace YOUR_AUTHORIZATION_CODE with the actual token
          },
        }
      );

      const {
        barData,
        featureTable,
        otherFeaturesTable,
        lineData,
        otherUsersTable,
        userTable,
        cards,
      } = response.data.data;

      setCards(cards);
      setBarData(barData);
      setFeatureTable(featureTable);
      setLineData(lineData);
      setOtherUserTable(otherUsersTable);
      setOtherFeatureTable(otherFeaturesTable);
      seUserTable(userTable);
    } catch (error) {
      handleReset();
      console.error("Error fetching data: ", error);
      if (error.response?.data?.message)
        toast.error(error.response.data.message);
    } finally {
      setLoading(false); // Set loading to false after data is fetched
    }
  };

  const onGenderChange = (value) => {
    setSelectedGender(value);
  };

  const onPaymentTierChange = (value) => {
    setSelectedPaymentTier(value);
  };

  const onUseCohortChange = (value) => {
    setSelectedUseCohort(value);
  };

  const onSelectedPageChange = (value) => {
    setSelectedPage(value);
  };

  const onSelectedDurationChange = (value) => {
    setSelectedDuration(value);
  };

  const onCohortPeriodChange = (value) => {
    setSelectedCohorts([]);
    setSelectedCohortPeriod(value);

    if (value !== "") {
      fetchCohorts(value);
    }
  };

  const handleProfileChange = (column, value) => {
    if (column) setSelectedUser(value);
  };

  const onCohortListChange = (event) => {
    const {
      target: { value },
    } = event;

    if (selectedCohortPeriod === "") {
      return toast.info("Please select a cohort period");
    }

    if (value.length > 7) {
      return toast.info("Maximum of 7 cohorts can be selected");
    }
    setSelectedCohorts(value);
  };

  const handleApply = () => {
    fetchData();
  };

  const handleReset = () => {
    setSelectedGender("all");
    setSelectedPaymentTier("all");
    setSelectedUseCohort("no");
    setUseUser("no");
    setSelectedPage("all");
    setSelectedDuration("month");
    setStartDate("");
    setEndDate("");
    setSelectedUser("");
    setSelectedCohorts([]);
    setCohorts([]);
    setSelectedCohortPeriod("");
  };

  const toggleActiveUser = () => {
    setUserActive(!userActive);
  };

  const toggleActiveFeature = () => {
    setFeatureActive(!featureActive);
  };

  return (
    <div>
      {loading && (
        <div className="overlay">
          <Loader />
        </div>
      )}
      {/* filter */}
      <Box style={{ marginTop: "20px" }}>
        <div className="card h-100">
          <div className="card-body">
            {/* gender */}
            <FormControl
              size="small"
              variant="outlined"
              style={{
                minWidth: 150,
                margin: "0 8px",
                height: "56px",
                marginTop: "8px",
              }}
            >
              <InputLabel id="gender">Gender</InputLabel>
              <Select
                labelId="gender"
                value={selectedGender || ""} // State to manage selected value
                onChange={(e) => onGenderChange(e.target.value)} // Handler to update the state
                label="gender"
                style={{ height: "100%" }}
              >
                <MenuItem value="all">All</MenuItem>
                <MenuItem value="Male">Male</MenuItem>
                <MenuItem value="Female">Female</MenuItem>
              </Select>
            </FormControl>

            {/* payment */}
            <FormControl
              size="small"
              variant="outlined"
              style={{
                minWidth: 150,
                margin: "0 8px",
                height: "56px",
                marginTop: "8px",
              }}
            >
              <InputLabel id="payment">Payment Tier</InputLabel>
              <Select
                labelId="payment"
                value={selectedPaymentTier || ""} // State to manage selected value
                onChange={(e) => onPaymentTierChange(e.target.value)} // Handler to update the state
                label="payment"
                style={{ height: "100%" }}
              >
                <MenuItem value="all">All</MenuItem>
                <MenuItem value="Tier One">Tier One</MenuItem>
                <MenuItem value="Tier Two">Tier Two</MenuItem>
              </Select>
            </FormControl>

            {/* use cohort */}
            <FormControl
              size="small"
              variant="outlined"
              style={{
                minWidth: 150,
                margin: "0 8px",
                height: "56px",
                marginTop: "8px",
              }}
            >
              <InputLabel id="useCohort">Use Cohort</InputLabel>
              <Select
                labelId="useCohort"
                value={useCohort || ""} // State to manage selected value
                onChange={(e) => onUseCohortChange(e.target.value)} // Handler to update the state
                label="useCohort"
                style={{ height: "100%" }}
              >
                <MenuItem value="yes">Yes</MenuItem>
                <MenuItem value="no">No</MenuItem>
              </Select>
            </FormControl>

            {/* page */}
            <FormControl
              size="small"
              variant="outlined"
              style={{
                minWidth: 150,
                margin: "0 8px",
                height: "56px",
                marginTop: "8px",
              }}
            >
              <InputLabel id="page">Page</InputLabel>
              <Select
                labelId="page"
                value={selectedPage || ""} // State to manage selected value
                onChange={(e) => onSelectedPageChange(e.target.value)} // Handler to update the state
                label="page"
                style={{ height: "100%" }}
              >
                {pages.map((page) => (
                  <MenuItem key={page} value={page}>
                    {page.charAt(0).toUpperCase() + page.slice(1)}{" "}
                    {/* Capitalize first letter */}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* duration */}
            <FormControl
              size="small"
              variant="outlined"
              style={{
                minWidth: 150,
                margin: "0 8px",
                height: "56px",
                marginTop: "8px",
              }}
            >
              <InputLabel id="duration">Duration</InputLabel>
              <Select
                labelId="page"
                value={selectedDuration || ""} // State to manage selected value
                onChange={(e) => onSelectedDurationChange(e.target.value)} // Handler to update the state
                label="page"
                style={{ height: "100%" }}
              >
                <MenuItem value="day">Day</MenuItem>
                <MenuItem value="week">Week</MenuItem>
                <MenuItem value="month">Month</MenuItem>
                <MenuItem value="year">Year</MenuItem>
                <MenuItem value="all">All Time</MenuItem>
                <MenuItem value="custom">Custom</MenuItem>
              </Select>
            </FormControl>

            {/* use user */}
            <FormControl
              size="small"
              variant="outlined"
              style={{
                minWidth: 150,
                margin: "0 8px",
                height: "56px",
                marginTop: "8px",
              }}
            >
              <InputLabel id="useUser">Use User</InputLabel>
              <Select
                labelId="useUser"
                value={useUser || ""} // State to manage selected value
                onChange={(e) => setUseUser(e.target.value)} // Handler to update the state
                label="useUser"
                style={{ height: "100%" }}
              >
                <MenuItem value="yes">Yes</MenuItem>
                <MenuItem value="no">No</MenuItem>
              </Select>
            </FormControl>

            {/* duration === "custom" */}
            {selectedDuration === "custom" && (
              <div>
                {/* Start Date */}
                <FormControl
                  size="small"
                  variant="outlined"
                  style={{
                    minWidth: 150,
                    margin: "0 8px",
                    height: "56px",
                    marginTop: "8px",
                  }}
                >
                  <TextField
                    label="Start Date"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    size="small"
                  />
                </FormControl>

                {/* End Date */}
                <FormControl
                  size="small"
                  variant="outlined"
                  style={{
                    minWidth: 150,
                    margin: "0 8px",
                    height: "56px",
                    marginTop: "8px",
                  }}
                >
                  <TextField
                    label="End Date"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    size="small"
                  />
                </FormControl>
              </div>
            )}

            {/* use cohort === "yes" */}
            {useCohort === "yes" && (
              <div>
                {/* cohort */}
                <FormControl
                  size="small"
                  variant="outlined"
                  style={{
                    minWidth: 150,
                    margin: "0 8px",
                    height: "56px",
                    marginTop: "8px",
                  }}
                >
                  <InputLabel id="cohortPeriod">Cohort Period</InputLabel>
                  <Select
                    labelId="cohortPeriod"
                    value={selectedCohortPeriod || ""} // State to manage selected value
                    onChange={(e) => onCohortPeriodChange(e.target.value)} // Handler to update the state
                    label="cohortPeriod"
                    style={{ height: "100%" }}
                  >
                    <MenuItem value="">Cohort Period</MenuItem>
                    <MenuItem value="day">Day</MenuItem>
                    <MenuItem value="week">Week</MenuItem>
                    <MenuItem value="month">Month</MenuItem>
                    <MenuItem value="year">Year</MenuItem>
                  </Select>
                </FormControl>

                {/* cohort */}
                <FormControl
                  size="small"
                  variant="outlined"
                  style={{
                    minWidth: 300,
                    margin: "0 8px",
                    height: "56px",
                    marginTop: "8px",
                  }}
                >
                  <InputLabel id="multi-cohort-select">
                    Select Cohorts
                  </InputLabel>
                  <Select
                    labelId="multi-cohort-select"
                    multiple
                    value={selectedCohorts}
                    onChange={onCohortListChange}
                    renderValue={(selected) =>
                      selected
                        .map(
                          (id) =>
                            cohorts.find((cohort) => cohort.id === id)?.name
                        )
                        .join(", ")
                    } // Displays selected cohort names
                    style={{ height: "100%" }}
                  >
                    {cohorts.map((cohort) => (
                      <MenuItem key={cohort.id} value={cohort.id}>
                        <Checkbox
                          checked={selectedCohorts.indexOf(cohort.id) > -1}
                        />
                        <div>
                          <ListItemText
                            primary={`${cohort.name} (${cohort.count})`}
                            secondary={`${cohort.start} to ${cohort.end}`}
                          />
                        </div>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            )}

            {/* use cohort === "yes" */}
            {useUser === "yes" && (
              <div>
                {/* cohort */}
                <FormControl
                  size="small"
                  variant="outlined"
                  style={{
                    minWidth: 150,
                    margin: "0 8px",
                    height: "56px",
                    marginTop: "8px",
                  }}
                >
                  <ProfileSearch
                    column={"profile"}
                    handleInputChange={handleProfileChange}
                  />
                </FormControl>
              </div>
            )}

            <div>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "black",
                  "&:hover": { backgroundColor: "black" },
                }}
                onClick={handleApply}
              >
                Apply
              </Button>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "white",
                  color: "black",
                  outlineColor: "black",
                  "&:hover": {
                    backgroundColor: "black",
                    color: "white",
                    outlineColor: "black",
                  },
                }}
                onClick={handleReset}
              >
                Reset
              </Button>
            </div>
          </div>
        </div>
      </Box>

      {/* line chart */}
      <Box style={{ marginTop: "20px" }}>
        <div className="card h-100">
          <div className="card-header">
            <h4 className="mb-0">Usage Trend</h4>
          </div>
          <div className="card-body">
            <SingleLineChart data={lineData} field={"key"} />
          </div>
        </div>
      </Box>

      {/* stats */}
      <Box style={{ marginTop: "20px" }}>
        <div className="row equal-height">
          <div className="col-md-12 mb-4">
            <div className="card h-100">
              <div className="card-body">
                <FeatureCards data={cards} />
              </div>
            </div>
          </div>
        </div>
      </Box>

      {/* bar chart */}
      <Box style={{ marginTop: "20px" }}>
        <div className="card h-100">
          <div className="card-header">
            <h4 className="mb-0">Pages and Features</h4>
          </div>
          <div className="card-body">
            <ClusteredBarChart data={barData} />
          </div>
        </div>
      </Box>

      {/* tables */}
      <Box style={{ marginTop: "20px" }}>
        <div className="row equal-height">
          {/* count*/}
          <div className="col-md-6 mb-4">
            <div className="card h-100">
              <div className="card-header d-flex justify-content-between align-items-center">
                <h4 className="mb-0">Users</h4>
                <div className="d-flex align-items-center">
                  <BlackSwitch
                    checked={userActive}
                    onChange={toggleActiveUser}
                    id="userActiveToggle"
                  />
                  <label
                    className="ms-2 mb-0"
                    htmlFor="userActiveToggle"
                    style={{ cursor: "pointer" }}
                  >
                    {userActive ? "Active" : "Inactive"}
                  </label>
                </div>
              </div>
              <div className="card-body">
                <DynamicTable data={userActive ? userTable : otherUserTable} />
              </div>
            </div>
          </div>
          {/* average*/}
          <div className="col-md-6 mb-4">
            <div className="card h-100">
              <div className="card-header d-flex justify-content-between align-items-center">
                <h4 className="mb-0">Features</h4>
                <div className="d-flex align-items-center">
                  <BlackSwitch
                    checked={featureActive}
                    onChange={toggleActiveFeature}
                    id="featureActiveToggle"
                  />
                  <label
                    className="ms-2 mb-0"
                    htmlFor="featureActiveToggle"
                    style={{ cursor: "pointer" }}
                  >
                    {featureActive ? "Active" : "Inactive"}
                  </label>
                </div>
              </div>
              <div className="card-body">
                <DynamicTable
                  data={featureActive ? featureTable : otherFeatureTable}
                />
              </div>
            </div>
          </div>
        </div>
      </Box>
      <ToastContainer />
    </div>
  );
}

export default Features;
