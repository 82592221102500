import React, { useState } from "react";
import axios from "../../api/axiosConfig";
import Loader from "../../components/Loader/Loader"; // Import your loader component
import InsertModal from "../../components/Popups/InsertRow";
import { ToastContainer, toast } from "react-toastify";
import BlogCard from "../../components/Blog Card/Blog Card";
import { Box, Grid, Pagination, Button } from "@mui/material";
import EditModal from "../../components/Popups/EditRow";

function Blog() {
  const [loading, setLoading] = useState(false); // State for loading
  const [blogData, setblogData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [formData, setFormData] = useState({}); // State for form data
  const [showInsertModal, setShowInsertModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedTable, setselectedTable] = useState("");
  const [blogID, setblogID] = useState("");
  const [rowID, setrowID] = useState("");

  const fetchblogData = async (value) => {
    try {
      const response = await axios.post("/news-notifs/fetch-blogs/", {
        page: value,
        limit: 10,
      });
      setblogData(response.data.data);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error("Error fetching blog data:", error);
      toast.error(error?.response?.data?.message || "Error fetching blogs");
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
    fetchblogData(value);
  };

  // Update handleInsertClick to set columns
  const handleInsertRowButton = async (table) => {
    setselectedTable(table);
    setFormData({});
    setShowInsertModal(true);
  };

  // Update handleInsertClick to set columns
  const handleEditRowButton = async (table, id) => {
    setrowID(id);
    setselectedTable(table);
    setFormData({});
    setShowEditModal(true);
  };

  const handleEditModalClose = () => {
    setShowEditModal(false);
    setselectedTable("");
    setblogID("");
    setrowID("");
  };

  const handleInsertModalClose = () => {
    setShowInsertModal(false);
    setselectedTable("");
    setblogID("");
  };

  const handleInputChange = (column, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [column]: value,
    }));
  };

  const handleInsertRow = async () => {
    try {
      setLoading(true);
      if (
        Object.keys(formData).length === 0 ||
        Object.values(formData).every((value) => value === "")
      ) {
        return toast.error(`You cannot send an empty data`);
      }

      if (rowID && rowID !== "") {
        formData.id = rowID;
      }

      const form = new FormData();
      form.append("selectedTable", selectedTable);
      Object.keys(formData).forEach((key) => {
        form.append(`data[${key}]`, formData[key]);
      });

      const response = await axios.post(
        "/insert/insert-single-without-symbol",
        form,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      if (response.status === 200) {
        toast.success("Data inserted successfully");
      } else {
        toast.error(`Unexpected response code: ${response.status}`);
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "Error inserting row");
      console.error("Error inserting data", error);
    } finally {
      setShowInsertModal(false);
      setLoading(false);
      setselectedTable("");
      setblogID("");
    }
  };

  const handleEditRow = async () => {
    try {
      setLoading(true);
      if (
        Object.keys(formData).length === 0 ||
        Object.values(formData).every((value) => value === "")
      ) {
        return toast.error(`You cannot send an empty data`);
      }

      const form = new FormData();
      Object.keys(formData).forEach((key) => {
        form.append(`data[${key}]`, formData[key]);
      });

      const response = await axios.patch("/insert/edit-row", form, {
        headers: { "Content-Type": "multipart/form-data" },
        params: { id: rowID, selectedTable },
      });
      if (response.status === 200) {
        toast.success("Row edited successfully");
      } else {
        toast.error(`Unexpected response code: ${response.status}`);
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "Error editing row");
      console.error("Error editing row", error);
    } finally {
      setShowEditModal(false);
      setLoading(false);
      setselectedTable("");
      setrowID("");
    }
  };

  return (
    <>
      {loading && (
        <div className="overlay">
          <Loader />
        </div>
      )}
      <Box sx={{ marginBottom: "20px" }}>
        <div className="card h-100">
          <div className="card-header">
            <h4 className="mb-0">Filters</h4>
          </div>
          <div className="card-body">
            <Button
              variant="contained"
              onClick={() => fetchblogData(1)}
              sx={{ backgroundColor: "black", color: "white" }}
            >
              Fetch
            </Button>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "black",
                color: "white",
                marginLeft: "20px",
              }}
              onClick={() => handleInsertRowButton("blog")}
            >
              Add Blog
            </Button>
          </div>
        </div>
      </Box>

      {/* Blog Cards Box */}
      <div className="row equal-height">
        <div className="col-md-12 mb-4">
          <div className="card h-100">
            <div className="card-header">
              <h4 className="mb-0">Blogs</h4>
            </div>
            <div className="card-body">
              <Pagination
                count={totalPages}
                page={page}
                onChange={handlePageChange}
                sx={{
                  marginTop: "20px",
                  marginBottom: "20px",
                  display: "flex",
                  justifyContent: "center",
                }}
              />
              <Grid container spacing={2}>
                {blogData.map((blogItem) => (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={blogItem.id}>
                    <BlogCard
                      blogItem={blogItem}
                      handleEditRowButton={handleEditRowButton}
                    />
                  </Grid>
                ))}
              </Grid>
              <Pagination
                count={totalPages}
                page={page}
                onChange={handlePageChange}
                sx={{
                  marginTop: "20px",
                  display: "flex",
                  justifyContent: "center",
                }}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Insert Modal Popup */}
      <div>
        <InsertModal
          show={showInsertModal}
          handleClose={handleInsertModalClose}
          tableTitle={selectedTable}
          handleInputChange={handleInputChange}
          handleInsertRow={handleInsertRow}
          foreignID={blogID}
        />
      </div>
      {/* Edit Modal Popup */}
      <div>
        <EditModal
          show={showEditModal}
          id={rowID}
          handleClose={handleEditModalClose}
          tableTitle={selectedTable}
          handleInputChange={handleInputChange}
          handleEditRow={handleEditRow}
          foreignID={blogID}
        />
      </div>
      <ToastContainer />
    </>
  );
}

export default Blog;
