import React, { useState, useEffect } from "react";
import axios from "../../api/axiosConfig";
import Loader from "../../components/Loader/Loader"; // Import your loader component
import { ToastContainer, toast } from "react-toastify";
//import { Modal, Button, Table } from "react-bootstrap"; // Import Bootstrap components
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import DynamicTable from "../../components/MUI Table/DynamicTable";
import "./../../components/MUI Table/DynamicTable.css";
//import InsertModal from "../../components/Popups/InsertRow";
import {
  Box,
  MenuItem,
  Select,
  Typography,
  Button,
  IconButton,
  Menu,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { Modal } from "react-bootstrap";
import { recommendation_schema } from "../../components/Models/tablesAndColumns";
import { SingleSymbolSearch } from "../../components/Popups/SymbolSearch";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditModal from "../../components/Popups/EditRow";

function Recommendations() {
  const [loading, setLoading] = useState(false); // State for loading
  const [selectedOption, setSelectedOption] = useState("");
  const [symbolID, setSymbolID] = useState(null);
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [showInsertModal, setShowInsertModal] = useState(false);
  const [description, setDescription] = useState(null);
  const [recommendationData, setRecommendationData] = useState([]);
  const [newAnchorEl, setNewAnchorEl] = useState(null);
  const [recommendationDataID, setrecommendationDataID] = useState(null);
  const [rowID, setrowID] = useState("");
  const [showEditModal, setShowEditModal] = useState(false);
  const [formData, setFormData] = useState({}); // State for form data

  useEffect(() => {
    fetchRecommendationData();
  }, []);

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
    handleInsertModalClose();
  };

  const handleFetch = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `/recommendation/get-recommendation-table`,
        {
          params: {
            selectedStock: symbolID,
            recommendationType: selectedOption,
          },
        }
      );
      if (response.status === 200) {
        setData(response.data.data);
        setShow(true);
      } else {
        toast.error(`Unexpected response code: ${response.status}`);
      }
    } catch (error) {
      if (error.response?.data?.message)
        toast.error(error.response.data.message);
      console.error("Error fetching data", error);
    } finally {
      setLoading(false);
    }
  };

  const handleViewClick = async () => {
    // Handle the view more action here
    await handleFetch();
  };

  const handleCloseModal = () => {
    setShow(false);
    setData([]);
    setDescription(null);
  };

  const handleCalculate = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `/recommendation/calculate-recommendation`,
        {
          params: {
            symbol_id: symbolID,
            recommendationType: selectedOption,
          },
        }
      );
      if (response.status === 200) {
        setData(response.data.data);
        setShow(true);
      } else {
        toast.error(`Unexpected response code: ${response.status}`);
      }
    } catch (error) {
      if (error.response?.data?.message)
        toast.error(error.response.data.message);
      console.error("Error fetching data", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCalculateClick = async () => {
    // Handle the view more action here
    await handleCalculate();
  };

  // Update handleInsertClick to set columns
  const handleRecommendAssetButton = async () => {
    setShowInsertModal(showInsertModal ? false : true);
  };

  const handleInsertModalClose = () => {
    setShowInsertModal(false);
    setDescription(null);
  };

  const handleInsertRow = async () => {
    if (!description) {
      return toast.warn(`Description should not be empty.`);
    }
    try {
      setLoading(true);
      const response = await axios.post(
        "/recommendation/insert-recommendation",
        {
          selectedStock: symbolID,
          recommendationType: selectedOption,
          description,
        }
      );
      if (response.status === 200) {
        toast.success("Successfully recommended the asset");
      } else {
        toast.error(`Unexpected response code: ${response.status}`);
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "Error recommending asset");
      console.error("Error inserting data", error);
    } finally {
      handleInsertModalClose();
      setLoading(false);
      setSymbolID(null);
      fetchRecommendationData();
    }
  };

  const handleNewOptionsClick = (event, recommendation) => {
    setNewAnchorEl(event.currentTarget);
    setrecommendationDataID(recommendation.id); // Store notification ID
  };

  const handleNewMenuClose = () => {
    setNewAnchorEl(null);
    setrecommendationDataID(null);
  };

  const fetchRecommendationData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/recommendation/get-recommended-data`);
      if (response.status === 200) {
        setRecommendationData(response.data.data);
      } else {
        toast.error(`Unexpected response code: ${response.status}`);
      }
    } catch (error) {
      if (error.response?.data?.message)
        toast.error(error.response.data.message);
      console.error("Error fetching data", error);
    } finally {
      setLoading(false);
    }
  };

  const handleEditRowButton = async (id) => {
    setrowID(id);
    setFormData({});
    setShowEditModal(true);
  };

  const handleEditModalClose = () => {
    setShowEditModal(false);
    setrecommendationDataID("");
    setrowID("");
  };

  const handleInputChange = (column, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [column]: value,
    }));
  };

  const handleEditRow = async () => {
    try {
      setLoading(true);
      if (
        Object.keys(formData).length === 0 ||
        Object.values(formData).every((value) => value === "")
      ) {
        return toast.error(`You cannot send an empty data`);
      }

      const form = new FormData();
      Object.keys(formData).forEach((key) => {
        form.append(`data[${key}]`, formData[key]);
      });

      const response = await axios.patch("/insert/edit-row", form, {
        headers: { "Content-Type": "multipart/form-data" },
        params: { id: rowID, selectedTable: "recommendations" },
      });
      if (response.status === 200) {
        toast.success("Row edited successfully");
      } else {
        toast.error(`Unexpected response code: ${response.status}`);
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "Error editing row");
      console.error("Error editing row", error);
    } finally {
      setShowEditModal(false);
      setrowID("");
      fetchRecommendationData();
    }
  };

  const deleteRecommendation = async (id) => {
    try {
      const isConfirmed = window.confirm(
        `Are you sure you want to delete this recommendation?`
      );
      if (isConfirmed) {
        setLoading(true);
        const response = await axios.delete(
          `/recommendation/delete-recommendation/${id}`
        );
        toast.success(response.data.status);
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.message || "Error deleting recommendation"
      );
      console.error("Error deleting recommendation:", error);
    } finally {
      setLoading(false);
      fetchRecommendationData();
    }
  };

  return (
    <>
      {loading && (
        <div className="overlay">
          <Loader />
        </div>
      )}
      <Box sx={{ marginBottom: "20px" }}>
        <div className="card h-100">
          <div className="card-header">
            <h4 className="mb-0"> Add Recommended Assets</h4>
          </div>
          <div className="card-body">
            <Box
              sx={{
                textAlign: "left",
              }}
            >
              <Select
                value={selectedOption}
                onChange={handleChange}
                displayEmpty
                sx={{ minWidth: 300, marginBottom: 2 }}
              >
                <MenuItem value="">Select a Recommendation Type</MenuItem>
                <MenuItem value="dividend">Dividend Stocks</MenuItem>
                <MenuItem value="enterprise">Enterprise Stocks</MenuItem>
                <MenuItem value="general">General Stocks</MenuItem>
                <MenuItem value="growth">Growth Stocks</MenuItem>
                <MenuItem value="passive">Passive Stocks</MenuItem>
              </Select>

              {selectedOption && (
                <Box mt={3}>
                  <Typography variant="body1" paragraph>
                    {recommendation_schema.definitions[selectedOption]}
                  </Typography>
                  <Typography variant="body1" paragraph>
                    Here is the list of requirements for the selected
                    recommendation:
                  </Typography>
                  <ul>
                    {recommendation_schema.requirements[selectedOption].map(
                      (req, index) => (
                        <li key={index}>{req || `Requirement ${index + 1}`}</li>
                      )
                    )}
                  </ul>
                  <Typography variant="body1" paragraph />

                  <Box sx={{ maxWidth: 300, marginBottom: 2 }}>
                    <SingleSymbolSearch setSymbolID={setSymbolID} />
                  </Box>

                  <Box mt={2} display="flex" gap={2}>
                    <Button
                      variant="contained"
                      sx={{ backgroundColor: "black" }}
                      onClick={() => {
                        handleViewClick();
                      }}
                      disabled={!symbolID}
                    >
                      View Data
                    </Button>
                    <Button
                      variant="contained"
                      sx={{ backgroundColor: "black" }}
                      onClick={() => {
                        handleCalculateClick();
                      }}
                      disabled={!symbolID}
                    >
                      Calculate
                    </Button>
                    <Button
                      variant="contained"
                      sx={{ backgroundColor: "black" }}
                      onClick={() => {
                        handleRecommendAssetButton();
                      }}
                      disabled={!symbolID}
                    >
                      Recommend Asset
                    </Button>
                  </Box>
                  {showInsertModal && (
                    <>
                      {/* Insert description */}
                      <Box
                        sx={{ maxWidth: 500, marginBottom: 2, marginTop: 2 }}
                      >
                        <textarea
                          style={{ width: "100%" }}
                          type="text"
                          value={description}
                          placeholder="Give reasons to justify this recommendation"
                          onChange={(e) => setDescription(e.target.value)}
                        />
                        <Box mt={2} display="flex" gap={2}>
                          <Button
                            variant="contained"
                            sx={{ backgroundColor: "black" }}
                            onClick={() => {
                              handleInsertRow();
                            }}
                            disabled={!symbolID}
                          >
                            Submit
                          </Button>
                          <Button
                            variant="contained"
                            sx={{ backgroundColor: "red" }}
                            onClick={() => {
                              handleInsertModalClose();
                            }}
                            disabled={!symbolID}
                          >
                            Close
                          </Button>
                        </Box>
                      </Box>
                    </>
                  )}
                </Box>
              )}
            </Box>
          </div>
        </div>
      </Box>
      {/* Modal Popup for Symbols */}
      <Modal
        show={show}
        onHide={handleCloseModal}
        dialogClassName="custom-modal"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="table-container">
            <DynamicTable className="dynamic-table" data={data} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Box sx={{ marginBottom: "20px" }}>
        <div className="card h-100">
          <div className="card-header">
            <h4 className="mb-0"> View Recommended Assets</h4>
          </div>
          <div className="card-body">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Recommendation Type</TableCell>
                  <TableCell>Symbol</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Recommended By</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {recommendationData.map((recommendation) => (
                  <TableRow key={recommendation.id}>
                    <TableCell>
                      <Typography variant="body1">
                        {
                          recommendation.recommendation_type_recommendation_type
                            .name
                        }
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1">
                        {recommendation.symbol_symbol.name}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1">
                        {recommendation.date?.split("T")[0]}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1">
                        {recommendation.recommended_by_profile.name}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1">
                        {recommendation.description}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <IconButton
                        onClick={(e) =>
                          handleNewOptionsClick(e, recommendation)
                        }
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        anchorEl={newAnchorEl}
                        open={Boolean(newAnchorEl)}
                        onClose={() => handleNewMenuClose()}
                      >
                        <MenuItem
                          onClick={() => {
                            // Add your Edit function here
                            handleEditRowButton(recommendationDataID);
                            handleNewMenuClose();
                          }}
                        >
                          Edit
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            // Add your Delete function here
                            deleteRecommendation(recommendationDataID);
                            setNewAnchorEl(null);
                          }}
                        >
                          Delete
                        </MenuItem>
                      </Menu>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
      </Box>
      {/* Edit Modal Popup */}
      <div>
        <EditModal
          show={showEditModal}
          id={rowID}
          handleClose={handleEditModalClose}
          tableTitle={"recommendations"}
          handleInputChange={handleInputChange}
          handleEditRow={handleEditRow}
          foreignID={recommendationDataID}
        />
      </div>
      <ToastContainer />
    </>
  );
}

export default Recommendations;
