import React from "react";
import ReactApexChart from "react-apexcharts";

const BarChart = ({ data, yAxis, xAxis, barColor = "#008FFB" }) => {
  // Extract the x-axis categories and y-axis data from the provided data
  const categories = data.map((item) => item[xAxis.field]);
  const seriesData = data.map((item) => item[yAxis.field]);

  // Chart options configuration
  const options = {
    chart: {
      type: "bar",
      height: 350,
      toolbar: {
        show: false,
      },
      zoom: { enabled: false }, // Disable zooming if not needed
    },
    colors: [barColor],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    grid: {
      show: false,
    },
    xaxis: {
      categories: categories,
      title: {
        text: xAxis.show ? xAxis.name : "",
        style: {
          fontSize: "14px",
          fontWeight: "bold",
          color: "#263238",
        },
      },
    },
    yaxis: {
      title: {
        text: yAxis.show ? yAxis.name : "",
        style: {
          fontSize: "14px",
          fontWeight: "bold",
          color: "#263238",
        },
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val;
        },
      },
    },
  };

  // Series data for the chart
  const series = [
    {
      name: yAxis.name,
      data: seriesData,
    },
  ];

  return (
    <div>
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={350}
      />
    </div>
  );
};

export default BarChart;
