import React from "react";
import Chart from "react-apexcharts";
import { useTheme } from "@mui/material";

const BarChart = ({ retentionBarData, xAxisLabel, yAxisLabel }) => {
  const theme = useTheme();

  // Prepare data for the chart
  const series = [
    {
      name: yAxisLabel,
      data: retentionBarData, // Use data directly as { x, y }
    },
  ];

  // Chart options
  const options = {
    chart: {
      type: "bar",
      height: 350,
      toolbar: {
        show: false,
      },
      zoom: { enabled: false }, // Disable zooming if not needed
    },
    colors: ["#1976d2"],
    plotOptions: {
      bar: {
        horizontal: false, // Vertical bars
        columnWidth: "50%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      type: "category", // Specify the x-axis as categorical
      labels: {
        style: {
          colors: theme.palette.text.primary,
          fontSize: "14px",
          fontWeight: "bold",
        },
      },
      title: {
        text: xAxisLabel, // Accept x-axis label from props
        style: {
          color: theme.palette.text.primary,
          fontSize: "16px",
          fontWeight: "bold",
        },
      },
    },
    yaxis: {
      labels: {
        show: false,
        style: {
          colors: theme.palette.text.primary,
          fontSize: "14px",
          fontWeight: "bold",
        },
      },
      title: {
        text: yAxisLabel, // Accept y-axis label from props
        style: {
          color: theme.palette.text.primary,
          fontSize: "16px",
          fontWeight: "bold",
        },
      },
    },
    grid: {
      show: false,
    },
    tooltip: {
      theme: "dark",
      y: {
        formatter: (value) => parseFloat(value.toFixed(2)), // Format tooltip values as percentages
      },
    },
    legend: {
      show: false,
    },
  };

  return (
    <div>
      <Chart options={options} series={series} type="bar" height={350} />
    </div>
  );
};

export default BarChart;
