import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
//import Loader from "../../components/Loader/Loader"; // Import your loader component
import Retention from "./retention";
import Features from "./feature";
import Payments from "./payment";

function Dashboard() {
  //const [loading, setLoading] = useState(false); // State for loading
  const [activeSection, setActiveSection] = useState(null); // Track which section is open

  // Toggle section visibility
  const toggleSection = (section) => {
    setActiveSection((prevSection) =>
      prevSection === section ? null : section
    );
  };

  return (
    <div>
      {/* {loading && (
        <div className="overlay">
          <Loader />
        </div>
      )} */}

      {/* User Retention Section */}
      <div
        style={{
          marginBottom: "16px",
          border: "1px solid #ccc",
          borderRadius: "8px",
        }}
      >
        <div
          style={{
            padding: "12px",
            cursor: "pointer",
            backgroundColor: "#f9f9f9",
            borderBottom:
              activeSection === "retention" ? "1px solid #ccc" : "none",
          }}
          onClick={() => toggleSection("retention")}
        >
          <strong>Retention Analytics</strong>
        </div>
        {activeSection === "retention" && (
          <div style={{ padding: "12px", backgroundColor: "#ffffff" }}>
            <Retention />
          </div>
        )}
      </div>

      {/* Features Section */}
      <div
        style={{
          marginBottom: "16px",
          border: "1px solid #ccc",
          borderRadius: "8px",
        }}
      >
        <div
          style={{
            padding: "12px",
            cursor: "pointer",
            backgroundColor: "#f9f9f9",
            borderBottom:
              activeSection === "features" ? "1px solid #ccc" : "none",
          }}
          onClick={() => toggleSection("features")}
        >
          <strong>Feature Analytics</strong>
        </div>
        {activeSection === "features" && (
          <div style={{ padding: "12px", backgroundColor: "#ffffff" }}>
            <Features />
          </div>
        )}
      </div>

      {/* Payments Section */}
      <div
        style={{
          marginBottom: "16px",
          border: "1px solid #ccc",
          borderRadius: "8px",
        }}
      >
        <div
          style={{
            padding: "12px",
            cursor: "pointer",
            backgroundColor: "#f9f9f9",
            borderBottom:
              activeSection === "payments" ? "1px solid #ccc" : "none",
          }}
          onClick={() => toggleSection("payments")}
        >
          <strong>Payment Analytics</strong>
        </div>
        {activeSection === "payments" && (
          <div style={{ padding: "12px", backgroundColor: "#ffffff" }}>
            <Payments />
          </div>
        )}
      </div>

      {/* Toast Notifications */}
      <ToastContainer />
    </div>
  );
}

export default Dashboard;
