import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import axios from "../../api/axiosConfig";
import realAxios from "axios";
import { fetchAuthSession } from "aws-amplify/auth";
import Loader from "../../components/Loader/Loader"; // Import your loader component
import {
  Box,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Checkbox,
  ListItemText,
} from "@mui/material";
import MultiLineChart from "../../components/Charts/Multiple Line Chart/MultipleLineChart";
import { RetentionCards } from "./components/DataCards";
import BoxPlotChart from "../../components/Charts/Box Plot/BoxPlot";
import BarChart from "./components/BarChart";

function Retention() {
  const [loading, setLoading] = useState(false); // State for loading
  const [linesData, setLinesData] = useState([]);
  const [stats, setStats] = useState({
    average: 0,
    weightedAverage: 0,
    mode: 0,
    min: 0,
    median: 0,
    max: 0,
  });
  const [boxPlotData, setBoxPlotData] = useState([]);
  const [retentionBarData, setRetentionBarData] = useState([]);
  const [cohortCount, setCohortCount] = useState([]);
  const [cohorts, setCohorts] = useState([]);
  const [selectedCohortPeriod, setSelectedCohortPeriod] = useState("");
  const [selectedCohorts, setSelectedCohorts] = useState([]);

  useEffect(() => {}, []);

  const fetchCohorts = async (period) => {
    try {
      setLoading(true);
      const response = await axios.get("/dashboard/get-cohorts", {
        params: {
          cohortPeriod: period,
        },
      });
      setCohorts(response.data.data);
    } catch (error) {
      restoreDefault();
      console.error("Error fetching data: ", error);
      if (error.response?.data?.message)
        toast.error(error.response.data.message);
    } finally {
      setLoading(false); // Set loading to false after data is fetched
    }
  };

  const fetchData = async (cohorts) => {
    try {
      setLoading(true);
      const authToken = (await fetchAuthSession()).tokens?.idToken?.toString();
      const response = await realAxios.post(
        "https://07prxr5v1m.execute-api.eu-west-2.amazonaws.com/prod/retension",
        { cohortPeriod: selectedCohortPeriod, selectedCohorts: cohorts },
        {
          headers: {
            Authorization: `Bearer ${authToken}`, // Replace YOUR_AUTHORIZATION_CODE with the actual token
          },
        }
      );

      const { linesData, stats, boxPlotData, retentionBarData, cohortCount } =
        response.data.data;
      setLinesData(linesData);
      setStats(stats);
      setBoxPlotData(boxPlotData);
      setRetentionBarData(retentionBarData);
      setCohortCount(cohortCount);
    } catch (error) {
      restoreDefault();
      console.error("Error fetching data: ", error);
      if (error.response?.data?.message)
        toast.error(error.response.data.message);
    } finally {
      setLoading(false); // Set loading to false after data is fetched
    }
  };

  const onCohortPeriodChange = (value) => {
    setSelectedCohorts([]);
    restoreDefault();
    setSelectedCohortPeriod(value);

    if (value !== "") {
      fetchCohorts(value);
    }
  };

  const onCohortListChange = (event) => {
    const {
      target: { value },
    } = event;

    if (selectedCohortPeriod === "") {
      return toast.info("Please select a cohort period");
    }

    if (value.length > 7) {
      return toast.info("Maximum of 7 cohorts can be selected");
    }

    if (value.length > 0) {
      fetchData(value);
    } else {
      restoreDefault();
    }
    setSelectedCohorts(value);
  };

  const restoreDefault = () => {
    setLinesData([]);
    setStats({
      average: 0,
      weightedAverage: 0,
      mode: 0,
      min: 0,
      median: 0,
      max: 0,
    });
    setBoxPlotData([]);
    setRetentionBarData([]);
    setCohortCount([]);
  };

  return (
    <div>
      {loading && (
        <div className="overlay">
          <Loader />
        </div>
      )}
      {/* filter */}
      <Box style={{ marginTop: "20px" }}>
        <div className="card h-100">
          <div className="card-body">
            <FormControl
              size="small"
              variant="outlined"
              style={{
                minWidth: 150,
                margin: "0 8px",
                height: "56px",
                marginTop: "8px",
              }}
            >
              <InputLabel id="cohortPeriod">Cohort Period</InputLabel>
              <Select
                labelId="cohortPeriod"
                value={selectedCohortPeriod || ""} // State to manage selected value
                onChange={(e) => onCohortPeriodChange(e.target.value)} // Handler to update the state
                label="cohortPeriod"
                style={{ height: "100%" }}
              >
                <MenuItem value="">Cohort Period</MenuItem>
                <MenuItem value="day">Day</MenuItem>
                <MenuItem value="week">Week</MenuItem>
                <MenuItem value="month">Month</MenuItem>
                <MenuItem value="year">Year</MenuItem>
              </Select>
            </FormControl>
            <FormControl
              size="small"
              variant="outlined"
              style={{
                minWidth: 300,
                margin: "0 8px",
                height: "56px",
                marginTop: "8px",
              }}
            >
              <InputLabel id="multi-cohort-select">Select Cohorts</InputLabel>
              <Select
                labelId="multi-cohort-select"
                multiple
                value={selectedCohorts}
                onChange={onCohortListChange}
                renderValue={(selected) =>
                  selected
                    .map(
                      (id) => cohorts.find((cohort) => cohort.id === id)?.name
                    )
                    .join(", ")
                } // Displays selected cohort names
                style={{ height: "100%" }}
              >
                {cohorts.map((cohort) => (
                  <MenuItem key={cohort.id} value={cohort.id}>
                    <Checkbox
                      checked={selectedCohorts.indexOf(cohort.id) > -1}
                    />
                    <div>
                      <ListItemText
                        primary={`${cohort.name} (${cohort.count})`}
                        secondary={`${cohort.start} to ${cohort.end}`}
                      />
                    </div>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
      </Box>

      {/* line chart */}
      <Box style={{ marginTop: "20px" }}>
        <div className="card h-100">
          <div className="card-header">
            <h4 className="mb-0">Retention Trend</h4>
          </div>
          <div className="card-body">
            <MultiLineChart linesData={linesData} yAxisRange={[0, 100]} />
          </div>
        </div>
      </Box>

      {/* stats */}
      <Box style={{ marginTop: "20px" }}>
        <div className="row equal-height">
          <div className="col-md-12 mb-4">
            <div className="card h-100">
              <div className="card-body">
                <RetentionCards data={stats} />
              </div>
            </div>
          </div>
        </div>
      </Box>

      {/* box plot */}
      <Box style={{ marginTop: "20px" }}>
        <div className="card h-100">
          <div className="card-header">
            <h4 className="mb-0">Retention Statistics</h4>
          </div>
          <div className="card-body">
            <BoxPlotChart data={boxPlotData} yAxisRange={[0, 100]} />
          </div>
        </div>
      </Box>

      {/* bars */}
      <Box style={{ marginTop: "20px" }}>
        <div className="row equal-height">
          {/* count*/}
          <div className="col-md-6 mb-4">
            <div className="card h-100">
              <div className="card-header">
                <h4 className="mb-0">Cohort User Count</h4>
              </div>
              <div className="card-body">
                <BarChart
                  retentionBarData={cohortCount}
                  xAxisLabel="Cohorts"
                  yAxisLabel="Count"
                />
              </div>
            </div>
          </div>
          {/* average*/}
          <div className="col-md-6 mb-4">
            <div className="card h-100">
              <div className="card-header">
                <h4 className="mb-0">Average Cohort Retention</h4>
              </div>
              <div className="card-body">
                <BarChart
                  retentionBarData={retentionBarData}
                  xAxisLabel="Cohorts"
                  yAxisLabel="Retention (%)"
                />
              </div>
            </div>
          </div>
        </div>
      </Box>
      <ToastContainer />
    </div>
  );
}

export default Retention;
