import {
  Card,
  CardContent,
  Typography,
  Grid,
  IconButton,
  List,
  ListItem,
  Tooltip,
  ListItemText,
  Box,
  Menu,
  MenuItem,
  Switch,
  Collapse,
  CardActions,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Pagination,
} from "@mui/material";
import {
  MoreVert as MoreVertIcon,
  Lightbulb as LightbulbIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from "@mui/icons-material";
import React, { useState, useEffect } from "react";
import axios from "../../api/axiosConfig";
import Loader from "../../components/Loader/Loader"; // Import your loader component
import { ToastContainer, toast } from "react-toastify";
import EditModal from "../../components/Popups/EditRow";
import InsertModal from "../../components/Popups/InsertRow";

function Subscriptions() {
  const [loading, setLoading] = useState(false); // State for loading
  const [live, setLive] = useState(0); // State for loading
  const [plansData, setPlansData] = useState([]); // State for loading
  const [anchorEl, setAnchorEl] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [rowID, setrowID] = useState(null); // State for loading
  const [formData, setFormData] = useState({}); // State for form data
  const [selectedTable, setselectedTable] = useState(null); // State for form data
  const [expandedService, setExpandedService] = useState(null);
  const [serviceAnchorEl, setServiceAnchorEl] = useState(null);
  const [showInsertModal, setShowInsertModal] = useState(false);
  const [payment_tier_service_id, setpayment_tier_service_id] = useState(null);
  const [subscriptionData, setsubscriptionData] = useState([]);
  const [subscriptionID, setsubscriptionID] = useState(null);
  const [newAnchorEl, setNewAnchorEl] = useState(null);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);

  useEffect(() => {
    fetchPlans();
    fetchSubscriptions(1);
  }, [live]);

  const changeEnvironment = async () => {
    if (live === 0) {
      const isConfirmed = window.confirm(`Are you sure you want to go live?`);
      if (!isConfirmed) {
        return;
      }
    }
    setLoading(true);
    try {
      const response = await axios.patch(
        `/subscription/update-payment-environment`
      );
      if (response.status === 200) {
        setLive(response.data.data.live);
      } else {
        toast.error(`Unexpected response code: ${response.status}`);
      }
    } catch (error) {
      if (error.response?.data?.message)
        toast.error(error.response.data.message);
      console.error("Error fetching data", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchPlans = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/subscription/fetch-plans`);
      if (response.status === 200) {
        setPlansData(response.data.data);
        setLive(response.data.live);
      } else {
        toast.error(`Unexpected response code: ${response.status}`);
      }
    } catch (error) {
      if (error.response?.data?.message)
        toast.error(error.response.data.message);
      console.error("Error fetching data", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchSubscriptions = async (value) => {
    setLoading(true);
    try {
      const response = await axios.post(`/subscription/fetch-subscriptions`, {
        page: value,
        limit: 20,
      });
      if (response.status === 200) {
        setsubscriptionData(response.data.data);
        setTotalPages(response.data.totalPages);
      } else {
        toast.error(`Unexpected response code: ${response.status}`);
      }
    } catch (error) {
      if (error.response?.data?.message)
        toast.error(error.response.data.message);
      console.error("Error fetching data", error);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
    fetchSubscriptions(value);
  };

  const handleMorePlanClick = (event, rowID) => {
    setAnchorEl(event.currentTarget);
    setrowID(rowID); // Log the plan ID
  };

  const handleMoreServiceClick = (event, rowID) => {
    setServiceAnchorEl(event.currentTarget);
    setrowID(rowID); // Save the selected service ID for editing or deleting
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditRowButton = () => {
    setShowEditModal(true);
    setAnchorEl(null);
    setServiceAnchorEl(null);
  };

  const handleEditModalClose = () => {
    setShowEditModal(false);
    setrowID(null);
    setServiceAnchorEl(null);
  };

  const handleInputChange = (column, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [column]: value,
    }));
  };

  const handleEditRow = async () => {
    try {
      setLoading(true);
      if (
        Object.keys(formData).length === 0 ||
        Object.values(formData).every((value) => value === "")
      ) {
        return toast.error(`You cannot send an empty data`);
      }

      const form = new FormData();
      Object.keys(formData).forEach((key) => {
        form.append(`data[${key}]`, formData[key]);
      });

      let response;

      const url =
        selectedTable === "payment_tier"
          ? "/subscription/update-plan"
          : "/insert/edit-row";

      if (selectedTable === "payment_tier") {
        response = await axios.put(url, form, {
          headers: { "Content-Type": "multipart/form-data" },
          params: { id: rowID, selectedTable },
        });
      } else {
        response = await axios.patch(url, form, {
          headers: { "Content-Type": "multipart/form-data" },
          params: { id: rowID, selectedTable },
        });
      }
      if (response.status === 200) {
        toast.success("Row edited successfully");
      } else {
        toast.error(`Unexpected response code: ${response.status}`);
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "Error editing row");
      console.error("Error editing row", error);
    } finally {
      setShowEditModal(false);
      setrowID(null);
      fetchPlans();
    }
  };

  const deletePlan = async (id) => {
    try {
      const isConfirmed = window.confirm(
        `Are you sure you want to delete this plan?`
      );
      if (isConfirmed) {
        setLoading(true);
        const response = await axios.delete(`/subscription/delete-plan/${id}`);
        toast.success(response.data.status);
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.message || "Error deleting recommendation"
      );
      console.error("Error deleting recommendation:", error);
    } finally {
      setLoading(false);
      fetchPlans();
    }
  };

  const deleteService = async (id, selectedTable) => {
    try {
      const isConfirmed = window.confirm(
        `Are you sure you want to delete this service from the plan?`
      );
      if (isConfirmed) {
        setLoading(true);
        const response = await axios.delete(`/insert/delete-row/`, {
          params: { id, selectedTable },
        });
        toast.success(response.data.status);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "Error deleting service");
      console.error("Error deleting service:", error);
    } finally {
      setselectedTable(null);
      setpayment_tier_service_id(null);
      setLoading(false);
      fetchPlans();
      setServiceAnchorEl(null);
    }
  };

  const handleExpandClick = (serviceId) => {
    setExpandedService(expandedService === serviceId ? null : serviceId);
  };

  const handleServiceClose = () => {
    setServiceAnchorEl(null);
  };

  const addRowButton = async () => {
    setServiceAnchorEl(null);
    setShowInsertModal(true);
  };

  const handleInsertModalClose = () => {
    setShowInsertModal(false);
    setselectedTable(null);
    setrowID(null);
  };

  const handleInsertRow = async () => {
    try {
      setLoading(true);
      if (
        Object.keys(formData).length === 0 ||
        Object.values(formData).every((value) => value === "")
      ) {
        return toast.error(`You cannot send an empty data`);
      }
      if (selectedTable === "payment_tier" || !formData.payment_tier) {
        formData.payment_tier = null; // If it's not already set, assign newsID
      }
      const form = new FormData();
      form.append("selectedTable", selectedTable);
      Object.keys(formData).forEach((key) => {
        form.append(`data[${key}]`, formData[key]);
      });

      const url =
        selectedTable === "payment_tier"
          ? "/subscription/create-plan/"
          : selectedTable === "payment_subscription"
          ? "/subscription/create-subscription/"
          : "/insert/insert-single-without-symbol";

      const response = await axios.post(url, form, {
        params: { selectedTable },
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (response.status === 200) {
        toast.success("Data inserted successfully");
      } else {
        toast.error(`Unexpected response code: ${response.status}`);
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "Error inserting row");
      console.error("Error inserting data", error);
    } finally {
      setShowInsertModal(false);
      setselectedTable(null);
      fetchPlans();
      fetchSubscriptions();
    }
  };

  const handleNewOptionsClick = (event, recommendation) => {
    setNewAnchorEl(event.currentTarget);
    setsubscriptionID(recommendation.id); // Store notification ID
  };

  const handleNewMenuClose = () => {
    setNewAnchorEl(null);
    setsubscriptionID(null);
  };

  const verifySubscription = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`/subscription/verify-payment`, {
        params: { subscription_id: subscriptionID },
      });
      toast.success(response.data.status);
    } catch (error) {
      toast.error(
        error?.response?.data?.message || "Error fetching subscriptions"
      );
      console.error("Error fetching subscriptions:", error);
    } finally {
      setLoading(false);
      fetchSubscriptions();
      setsubscriptionID(null);
    }
  };

  const disableSubscription = async () => {
    try {
      const isConfirmed = window.confirm(
        `Are you sure you want to disable this subscription?`
      );
      if (isConfirmed) {
        setLoading(true);
        const response = await axios.put(
          `/subscription/disable-subscription/${subscriptionID}`
        );
        toast.success(response.data.status);
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.message || "Error fetching subscriptions"
      );
      console.error("Error fetching subscriptions:", error);
    } finally {
      setLoading(false);
      fetchSubscriptions();
      setsubscriptionID(null);
    }
  };

  return (
    <>
      {loading && (
        <div className="overlay">
          <Loader />
        </div>
      )}
      {/* filters section*/}
      <Box sx={{ marginBottom: "20px" }}>
        <div className="card h-100">
          <div className="card-header">
            <h4 className="mb-0">Filters and Environment</h4>
          </div>
          <div className="card-body">
            <Switch
              checked={live === 1}
              onChange={changeEnvironment}
              sx={{
                "& .MuiSwitch-thumb": { color: "black" },
                "& .MuiSwitch-track": { backgroundColor: "black" },
              }}
            />
          </div>
        </div>
      </Box>
      {/* plans section*/}
      <Box sx={{ marginBottom: "20px" }}>
        <div className="card h-100">
          <div className="card-header">
            <h4 className="mb-0"> Payment Tiers</h4>
          </div>
          <div className="card-body">
            <Button
              style={{
                backgroundColor: "black",
                color: "white",
                marginBottom: "10px",
              }}
              onClick={(e) => {
                setselectedTable("payment_tier");
                addRowButton();
              }}
            >
              Add Plan
            </Button>
            <Grid container spacing={3}>
              {plansData.map((plan) => (
                <Grid item xs={12} sm={6} md={4} key={plan.id}>
                  <Card sx={{ position: "relative" }}>
                    {/* Live indicator (bulb) */}
                    <Box sx={{ position: "absolute", top: 8, left: 8 }}>
                      <Tooltip
                        title={plan.live ? "Plan is live" : "Plan is not live"}
                      >
                        <LightbulbIcon
                          sx={{ color: plan.live ? "yellow" : "grey" }}
                        />
                      </Tooltip>
                    </Box>

                    {/* More options icon */}
                    <Box sx={{ position: "absolute", top: 8, right: 8 }}>
                      <IconButton
                        onClick={(e) => {
                          setselectedTable("payment_tier");
                          handleMorePlanClick(e, plan.id);
                        }}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                      >
                        <MenuItem onClick={handleEditRowButton}>Edit</MenuItem>
                        <MenuItem
                          onClick={() => {
                            // Add your Delete function here
                            deletePlan(rowID);
                            setAnchorEl(null);
                          }}
                        >
                          Delete
                        </MenuItem>
                      </Menu>
                    </Box>

                    <CardContent>
                      {/* Plan details */}
                      <Typography variant="h6">{plan.name}</Typography>
                      <Typography variant="subtitle2" color="textSecondary">
                        Plan Code: {plan.plan_code}
                      </Typography>
                      <Typography variant="body2">
                        Price: {plan.price * 1}
                      </Typography>
                      <Typography variant="body2">
                        Duration: {plan.duration}
                      </Typography>
                      <Typography variant="body2" gutterBottom>
                        {plan.description}
                      </Typography>

                      {/* Plan services */}
                      <Typography variant="subtitle1" gutterBottom>
                        Services:
                      </Typography>
                      <List>
                        {plan.payment_tier_services.map((service) => (
                          <div key={service.id}>
                            <ListItem
                              button
                              onClick={() => {
                                setselectedTable("payment_service");
                                setrowID(
                                  service.payment_service_payment_service.id
                                );
                                handleExpandClick(service.id);
                              }}
                            >
                              {expandedService === service.id ? (
                                <ExpandLessIcon />
                              ) : (
                                <ExpandMoreIcon />
                              )}
                              <ListItemText
                                primary={
                                  service.payment_service_payment_service.name
                                }
                              />
                              {/* More Options icon for each service */}
                              <IconButton
                                onClick={(e) => {
                                  setselectedTable("payment_service");
                                  handleMoreServiceClick(
                                    e,
                                    service.payment_service_payment_service.id
                                  );
                                  setpayment_tier_service_id(service.id);
                                }}
                              >
                                <MoreVertIcon />
                              </IconButton>
                            </ListItem>

                            {/* Dropdown with description */}
                            <Collapse
                              in={expandedService === service.id}
                              timeout="auto"
                              unmountOnExit
                            >
                              <Typography sx={{ pl: 4, color: "gray" }}>
                                {
                                  service.payment_service_payment_service
                                    .description
                                }
                              </Typography>
                            </Collapse>

                            {/* Menu for Edit/Delete options */}
                            <Menu
                              anchorEl={serviceAnchorEl}
                              open={Boolean(serviceAnchorEl)}
                              onClose={handleServiceClose}
                            >
                              <MenuItem onClick={handleEditRowButton}>
                                Edit
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  deleteService(
                                    payment_tier_service_id,
                                    "payment_tier_service"
                                  );
                                }}
                              >
                                Delete
                              </MenuItem>
                            </Menu>
                          </div>
                        ))}
                      </List>
                      <CardActions>
                        <Button
                          style={{ backgroundColor: "black", color: "white" }}
                          onClick={(e) => {
                            setselectedTable("payment_tier_service");
                            addRowButton();
                          }}
                        >
                          Add Service
                        </Button>
                      </CardActions>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </div>
        </div>
      </Box>
      {/* subscription secrion*/}
      <Box sx={{ marginBottom: "20px" }}>
        <div className="card h-100">
          <div className="card-header">
            <h4 className="mb-0"> Subscriptions</h4>
          </div>
          <div className="card-body">
            <Pagination
              count={totalPages}
              page={page}
              onChange={handlePageChange}
              sx={{
                marginTop: "20px",
                marginBottom: "20px",
                display: "flex",
                justifyContent: "center",
              }}
            />
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <LightbulbIcon sx={{ color: live ? "yellow" : "grey" }} />{" "}
                    Payment Tier
                  </TableCell>
                  <TableCell>Customer</TableCell>
                  <TableCell>Transaction</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Duration</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {subscriptionData.map((subscription) => (
                  <TableRow key={subscription.id}>
                    <TableCell>
                      <Typography variant="body1">
                        {subscription.payment_tier_payment_tier.name}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1">
                        {subscription.profile_profile.name}
                      </Typography>
                      <Typography variant="body2">
                        {subscription.profile_profile.email}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1">
                        {subscription.subscription_code}
                      </Typography>
                      <Typography variant="body2">
                        {subscription.reference}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1">
                        {subscription.status}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">
                        From: {subscription.start_date}
                      </Typography>
                      <Typography variant="body2">
                        To: {subscription.end_date}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <IconButton
                        onClick={(e) => {
                          handleNewOptionsClick(e, subscription);
                        }}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        anchorEl={newAnchorEl}
                        open={Boolean(newAnchorEl)}
                        onClose={() => {
                          handleNewMenuClose();
                        }}
                      >
                        <MenuItem
                          onClick={() => {
                            // Add your Edit function here
                            verifySubscription();
                            handleNewMenuClose();
                          }}
                        >
                          Verify
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            // Add your Delete function here
                            disableSubscription();
                            setNewAnchorEl(null);
                          }}
                        >
                          Disable
                        </MenuItem>
                      </Menu>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Pagination
              count={totalPages}
              page={page}
              onChange={handlePageChange}
              sx={{
                marginTop: "20px",
                marginBottom: "20px",
                display: "flex",
                justifyContent: "center",
              }}
            />
            <Button
              style={{
                backgroundColor: "black",
                color: "white",
                marginBottom: "10px",
              }}
              onClick={(e) => {
                setselectedTable("payment_subscription");
                addRowButton();
              }}
            >
              Add Subscripton
            </Button>
          </div>
        </div>
      </Box>
      {/* Edit Modal Popup */}
      <div>
        <EditModal
          show={showEditModal}
          id={rowID}
          handleClose={handleEditModalClose}
          tableTitle={selectedTable}
          handleInputChange={handleInputChange}
          handleEditRow={handleEditRow}
          foreignID={rowID}
        />
      </div>
      {/* Insert Modal Popup */}
      <div>
        <InsertModal
          show={showInsertModal}
          handleClose={handleInsertModalClose}
          tableTitle={selectedTable}
          handleInputChange={handleInputChange}
          handleInsertRow={handleInsertRow}
          foreignID={rowID}
        />
      </div>
      <ToastContainer />
    </>
  );
}

export default Subscriptions;
