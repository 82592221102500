import React from "react";

function Dashboard() {
  return (
    <div>
      <h1>Assessworth Admin</h1>
      <p>Welcome to the home page!</p>
    </div>
  );
}

export default Dashboard;
