import React, { useState, useEffect } from "react";
import axios from "./../../api/axiosConfig";
import { FaSearch } from "react-icons/fa";

const ProfileSearch = ({ handleInputChange, column }) => {
  const [query, setQuery] = useState("");
  const [filteredProfiles, setFilteredProfiles] = useState([]);

  useEffect(() => {
    if (query.length === 0) {
      setFilteredProfiles([]);
    }
  }, [query]);

  const fetchProfiles = async (searchTerm) => {
    try {
      const response = await axios.get(`/compare/get-profiles`, {
        params: { query: searchTerm },
      });
      setFilteredProfiles(response.data);
    } catch (error) {
      console.error("Error fetching profiles:", error);
    }
  };

  const handleSearch = () => {
    if (query.length > 0) {
      fetchProfiles(query);
    } else {
      setFilteredProfiles([]);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const handleSelect = (id) => {
    const selectedProfile = filteredProfiles.find(
      (profile) => profile.id === id
    );
    handleInputChange(column, id);
    setQuery(selectedProfile.name); // Set the name in the input field
    setFilteredProfiles([]);
  };

  return (
    <div style={{ position: "relative" }}>
      <input
        type="text"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        onKeyDown={handleKeyDown}
        placeholder="Search by name or email"
        style={{ width: "100%", padding: "8px" }}
      />
      <button
        onClick={handleSearch}
        style={{
          background: "none",
          border: "none",
          position: "absolute",
          right: "8px",
          top: "8px",
          cursor: "pointer",
        }}
      >
        <FaSearch />
      </button>
      {filteredProfiles.length > 0 && (
        <ul
          style={{
            position: "absolute",
            top: "100%",
            left: 0,
            right: 0,
            maxHeight: "200px",
            overflowY: "auto",
            border: "1px solid #ccc",
            backgroundColor: "#fff",
            listStyleType: "none",
            padding: 0,
            margin: 0,
            zIndex: 1000,
          }}
        >
          {filteredProfiles.map((profile) => (
            <li
              key={profile.id}
              onClick={() => {
                handleSelect(profile.id);
                setFilteredProfiles([]);
              }}
              style={{
                padding: "10px",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              <img
                src={profile.icon || "/user.png"}
                alt="User Icon"
                style={{ width: "20px", marginRight: "8px" }}
              />
              <div style={{ flex: 1 }}>
                <span
                  style={{
                    fontWeight: "bold",
                    color: profile.admin_level > 0 ? "blue" : "black",
                  }}
                >
                  {profile.name}
                </span>
                <br />
                <span style={{ fontSize: "12px", color: "#555" }}>
                  {profile.email}
                </span>
              </div>
              <img
                src={profile.country || "/Earth.png"}
                alt="Country"
                style={{ width: "20px", marginLeft: "8px" }}
              />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default ProfileSearch;
