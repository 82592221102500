import React from "react";
import Chart from "react-apexcharts";
import { useTheme } from "@mui/material";

const MultiLineChart = ({ linesData, yAxisRange }) => {
  const theme = useTheme();

  // Define an array of colors to cycle through, or use theme colors for each line
  const colors = [
    "#1976d2", // primary.main
    "#7b1fa2", // added an extra color
    "#dc004e", // secondary.main
    "#388e3c", // success.main
    "#d32f2f", // error.main
    "#f57c00", // warning.main
    "#0288d1", // info.main
  ];

  // Prepare the series data for ApexCharts
  const series = linesData.map((line, index) => ({
    name: line.name,
    data: line.data.map((item) => ({ x: item.x, y: item.y })),
  }));

  // Configure chart options
  const options = {
    chart: {
      type: "line",
      height: 350,
      toolbar: { show: false },
      zoom: { enabled: false }, // Disable zooming if not needed
    },
    colors: colors,
    stroke: {
      width: 2,
      curve: "smooth", // Makes the lines smoother
    },
    xaxis: {
      type: "category",
      labels: {
        style: {
          colors: theme.palette.text.primary,
          fontSize: "14px",
          fontWeight: "bold",
        },
      },
      tickAmount: 15, // Limit the number of x-axis ticks
      title: {
        style: {
          color: theme.palette.text.primary,
          fontSize: "14px",
          fontWeight: "bold",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: theme.palette.text.primary,
          fontSize: "14px",
          fontWeight: "bold",
        },
        formatter: (value) => {
          return Math.round(value); // Round the value to the nearest integer (0 decimal places)
        },
      },
      min: yAxisRange ? yAxisRange[0] : undefined,
      max: yAxisRange ? yAxisRange[1] : undefined,
      title: {
        style: {
          color: theme.palette.text.primary,
          fontSize: "14px",
          fontWeight: "bold",
        },
      },
    },
    grid: {
      show: false, // Disable grid lines
    },
    legend: {
      show: true,
      labels: {
        colors: theme.palette.text.primary,
      },
    },
    tooltip: {
      theme: "dark",
      x: {
        show: true,
      },
      y: {
        formatter: (value) => value.toFixed(2), // Format tooltip values to two decimals
      },
    },
  };

  return (
    <div>
      <Chart options={options} series={series} type="line" height={350} />
    </div>
  );
};

export default MultiLineChart;
