import React, { useState, useEffect } from "react";
import axios from "../../api/axiosConfig";
import Loader from "../../components/Loader/Loader"; // Import your loader component
import { ToastContainer, toast } from "react-toastify";
import {
  Typography,
  IconButton,
  Box,
  Menu,
  MenuItem,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { MoreVert as MoreVertIcon } from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import InsertModal from "./../../components/Popups/InsertRow";

function Education() {
  const [loading, setLoading] = useState(false); // State for loading
  const [imageData, setImageData] = useState([]);
  const [currentImagePage, setCurrentImagePage] = useState(1);
  const imagesPerPage = 2; // Number of images to show per page
  const [selectedTable, setSelectedTable] = useState(null);
  const [showInsertModal, setShowInsertModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [formData, setFormData] = useState({}); // State for form data
  const [quoteData, setQuoteData] = useState([]); // State for form data
  const [quoteAnchorEl, setQuoteAnchorEl] = useState(null);
  const [questionData, setQuestionData] = useState([]); // State for form data
  const [questionAnchorEl, setQuestionAnchorEl] = useState(null);

  useEffect(() => {
    fetchImages();
    fetchQuotes();
    fetchQuestions();
  }, []);

  const fetchImages = async () => {
    setLoading(true);
    try {
      const response = await axios.get("/education/fetch-images/");
      setImageData(response.data.data);
    } catch (error) {
      console.error("Error:", error);
      toast.error(error?.response?.data?.message || "Error");
    } finally {
      setLoading(false);
    }
  };

  const fetchQuotes = async () => {
    setLoading(true);
    try {
      const response = await axios.get("/education/fetch-quotes/");
      setQuoteData(response.data.data);
    } catch (error) {
      console.error("Error:", error);
      toast.error(error?.response?.data?.message || "Error");
    } finally {
      setLoading(false);
    }
  };

  const fetchQuestions = async () => {
    setLoading(true);
    try {
      const response = await axios.get("/education/fetch-questions/");
      setQuestionData(response.data.data);
      console.log(response.data.data);
    } catch (error) {
      console.error("Error:", error);
      toast.error(error?.response?.data?.message || "Error");
    } finally {
      setLoading(false);
    }
  };

  // Delete Image Function
  const deleteImage = async (id) => {
    try {
      setLoading(true);
      await axios.delete(`/education/delete-image/${id}`);
      toast.success("Image deleted successfully");
      setImageData((prevData) => prevData.filter((image) => image.id !== id));
    } catch (error) {
      console.error("Error:", error);
      toast.error(error?.response?.data?.message || "Error deleting image");
    } finally {
      setLoading(false);
    }
  };

  // Delete Image Function
  const addImage = async (table) => {
    try {
      setSelectedTable(table);
      setShowInsertModal(true);
    } catch (error) {
      console.error("Error:", error);
      toast.error(error?.response?.data?.message || "Error adding image");
    }
  };

  // Calculate the current images to display based on the current page
  const indexOfLastImage = currentImagePage * imagesPerPage;
  const indexOfFirstImage = indexOfLastImage - imagesPerPage;
  const currentImages = imageData.slice(indexOfFirstImage, indexOfLastImage);

  // Handle page change
  const paginate = (pageNumber) => setCurrentImagePage(pageNumber);

  const handleInsertModalClose = () => {
    setShowInsertModal(false);
    setSelectedFile(null);
    setSelectedTable(null);
    setFormData({});
  };

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      const fileType = e.target.files[0].type;
      const validImageTypes = ["image/jpeg", "image/png"];

      if (!validImageTypes.includes(fileType)) {
        toast.error("Please upload a valid image file (JPEG or PNG).");
        setSelectedFile(null);
        return;
      }
    }
    setSelectedFile(e.target.files[0]);
  };

  const handleInputChange = (column, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [column]: value,
    }));
  };

  const handleInsertRow = async () => {
    try {
      setLoading(true);
      // Ensure at least one valid form field or the selectedFile exists
      const hasValidData =
        (Object.keys(formData).length > 0 &&
          Object.values(formData).some((value) => value !== "")) ||
        selectedFile;

      if (!hasValidData) {
        return toast.error(`You cannot send an empty data`);
      }

      const form = new FormData();
      Object.keys(formData).forEach((key) => {
        form.append(`data[${key}]`, formData[key]);
      });

      // Handle file input separately
      if (selectedFile && selectedFile instanceof File) {
        form.append("file", selectedFile);
      }

      let url = "";

      if (selectedTable === "education_image") {
        url = "/education/add-image";
      }

      const response = await axios.post(url, form, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (response.status === 200) {
        toast.success("Data inserted successfully");

        await fetchImages();
      } else {
        toast.error(`Unexpected response code: ${response.status}`);
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "Error inserting row");
      console.error("Error inserting data", error);
    } finally {
      setFormData({});
      setShowInsertModal(false);
      setSelectedFile(null);
      setLoading(false);
      setSelectedTable(null);
    }
  };

  const handleQuoteOptionsClick = (event) => {
    setQuoteAnchorEl(event.currentTarget);
  };

  const handleQuoteMenuClose = () => {
    setQuoteAnchorEl(null);
  };

  const actionQuote = async (quote_id, action) => {
    setLoading(true);
    try {
      await axios.post("/education/action-quote/", {
        quote_id,
        action,
      });
      toast.success(`quote ${action}ed successfully`);
    } catch (error) {
      console.error("Error:", error);
      toast.error(error?.response?.data?.message || "Error");
    } finally {
      fetchQuotes();
      setLoading(false);
    }
  };

  const handleQuestionOptionsClick = (event) => {
    setQuestionAnchorEl(event.currentTarget);
  };

  const handleQuestionMenuClose = () => {
    setQuestionAnchorEl(null);
  };

  const actionQuestion = async (question_id, action) => {
    setLoading(true);
    try {
      await axios.post("/education/action-question/", {
        question_id,
        action,
      });
      toast.success(`quote ${question_id} successfully`);
    } catch (error) {
      console.error("Error:", error);
      toast.error(error?.response?.data?.message || "Error");
    } finally {
      fetchQuestions();
      setLoading(false);
    }
  };

  return (
    <>
      {loading && (
        <div className="overlay">
          <Loader />
        </div>
      )}

      {/* Images */}
      <Box sx={{ marginBottom: "20px" }}>
        <div className="card h-100">
          <div className="card-header">
            <h4 className="mb-0">Images</h4>
          </div>
          <div className="card-body">
            <button
              onClick={() => addImage("education_image")}
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                backgroundColor: "black",
                color: "white",
                border: "none",
                borderRadius: "5px",
                padding: "5px",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Add Image
            </button>
            {/* Image Grid */}
            <div
              className="image-gallery"
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
                gap: "20px",
                justifyContent: "center",
              }}
            >
              {currentImages.map((image) => (
                <div
                  key={image.id}
                  className="image-container"
                  style={{
                    position: "relative",
                    width: "100%",
                    height: "200px", // Fixed height
                    borderRadius: "8px",
                    overflow: "hidden",
                    boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
                  }}
                >
                  <img
                    src={image.url}
                    alt="Motivational"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover", // Crop and maintain aspect ratio
                    }}
                  />
                  <button
                    onClick={() => deleteImage(image.id)}
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                      backgroundColor: "rgba(255, 0, 0, 0.7)",
                      color: "white",
                      border: "none",
                      borderRadius: "50%",
                      padding: "5px",
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <FontAwesomeIcon icon={faTrash} size="sm" />
                  </button>
                </div>
              ))}
            </div>

            {/* Pagination Buttons */}
            <div
              className="pagination"
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
                gap: "10px",
              }}
            >
              {Array.from({
                length: Math.ceil(imageData.length / imagesPerPage),
              }).map((_, index) => (
                <button
                  key={index}
                  onClick={() => paginate(index + 1)}
                  style={{
                    padding: "8px 12px",
                    border: "none",
                    borderRadius: "4px",
                    backgroundColor:
                      currentImagePage === index + 1 ? "#007bff" : "#ccc",
                    color: "white",
                    cursor: "pointer",
                  }}
                >
                  {index + 1}
                </button>
              ))}
            </div>
          </div>
        </div>
      </Box>

      {/* Quotes */}
      <Box sx={{ marginBottom: "20px" }}>
        <div className="card h-100">
          <div className="card-header">
            <h4 className="mb-0">Quotes</h4>
          </div>
          <div className="card-body">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Profile</TableCell>
                  <TableCell>Quote</TableCell>
                  <TableCell>Author</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {quoteData.map((quote) => (
                  <TableRow key={quote.id}>
                    <TableCell>
                      <Typography variant="body1">{quote.date}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1">
                        {quote.profile_profile.name}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1">{quote.quote}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1">{quote.author}</Typography>
                    </TableCell>
                    <TableCell>
                      <IconButton
                        onClick={(e) => {
                          handleQuoteOptionsClick(e);
                        }}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        anchorEl={quoteAnchorEl}
                        open={Boolean(quoteAnchorEl)}
                        onClose={() => {
                          handleQuoteMenuClose();
                        }}
                      >
                        <MenuItem
                          onClick={() => {
                            // Add your Edit function here
                            actionQuote(quote.id, "approve");
                          }}
                        >
                          Approve
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            // Add your Delete function here
                            actionQuote(quote.id, "delete");
                          }}
                        >
                          Delete
                        </MenuItem>
                      </Menu>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {quoteData.length < 1 && (
              <>
                <div>No due quotes for approval</div>
              </>
            )}
          </div>
        </div>
      </Box>

      {/* Courses */}
      {/* <Box sx={{ marginBottom: "20px" }}>
        <div className="card h-100">
          <div className="card-header">
            <h4 className="mb-0">Courses</h4>
          </div>
          <div className="card-body"></div>
        </div>
      </Box> */}

      {/* Questions */}
      <Box sx={{ marginBottom: "20px" }}>
        <div className="card h-100">
          <div className="card-header">
            <h4 className="mb-0">Questions</h4>
          </div>
          <div className="card-body">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Profile</TableCell>
                  <TableCell>Question</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {questionData.map((question) => (
                  <TableRow key={question.id}>
                    <TableCell>
                      <Typography variant="body1">{question.date}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1">
                        {question.author_profile.name}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1">
                        {question.question}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1">
                        {question.status === 0 ? "Not Answered" : "Answered"}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <IconButton
                        onClick={(e) => {
                          handleQuestionOptionsClick(e, question);
                        }}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        anchorEl={questionAnchorEl}
                        open={Boolean(questionAnchorEl)}
                        onClose={() => {
                          handleQuestionMenuClose();
                        }}
                      >
                        {question.status === 0 && (
                          <>
                            <MenuItem
                              onClick={() => {
                                // Add your Edit function here
                                actionQuestion(question.id, "answered");
                              }}
                            >
                              Answered
                            </MenuItem>
                          </>
                        )}
                        <MenuItem
                          onClick={() => {
                            // Add your Delete function here
                            actionQuestion(question.id, "delete");
                          }}
                        >
                          Delete
                        </MenuItem>
                      </Menu>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {questionData.length < 1 && (
              <>
                <div>No questions available</div>
              </>
            )}
          </div>
        </div>
      </Box>

      {/* Glossary */}
      {/* <Box sx={{ marginBottom: "20px" }}>
        <div className="card h-100">
          <div className="card-header">
            <h4 className="mb-0">Glossary</h4>
          </div>
          <div className="card-body"></div>
        </div>
      </Box> */}
      {/* Insert Modal Popup */}
      <div>
        <InsertModal
          show={showInsertModal}
          handleClose={handleInsertModalClose}
          tableTitle={selectedTable}
          handleFileChange={handleFileChange}
          handleInputChange={handleInputChange}
          handleInsertRow={handleInsertRow}
        />
      </div>
      <ToastContainer />
    </>
  );
}

export default Education;
