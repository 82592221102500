import React from "react";
import { Card, CardContent, Typography, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const RetentionCards = ({ data }) => {
  const theme = useTheme();

  const cardStyle = {
    minWidth: 200,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 2,
    boxShadow: 3,
    padding: 2,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };

  const labelStyle = {
    color: theme.palette.text.secondary,
    fontSize: "0.875rem",
    fontWeight: 600,
  };

  const valueStyle = {
    color: theme.palette.text.primary,
    fontSize: "1.5rem",
    fontWeight: 700,
  };

  return (
    <Grid container spacing={3} justifyContent="center">
      <Grid item xs={12} sm={6} md={4}>
        <Card sx={cardStyle}>
          <CardContent>
            <Typography sx={valueStyle}>
              {parseFloat(data?.average?.toFixed(2) || 0)}
            </Typography>
            <Typography sx={labelStyle}>Average</Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Card sx={cardStyle}>
          <CardContent>
            <Typography sx={valueStyle}>
              {parseFloat(data?.weightedAverage?.toFixed(2) || 0)}
            </Typography>
            <Typography sx={labelStyle}>Weighted Average</Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Card sx={cardStyle}>
          <CardContent>
            <Typography sx={valueStyle}>
              {parseFloat(data.mode.toFixed(2))}
            </Typography>
            <Typography sx={labelStyle}>Mode</Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Card sx={cardStyle}>
          <CardContent>
            <Typography sx={valueStyle}>
              {parseFloat(data.min.toFixed(2))}
            </Typography>
            <Typography sx={labelStyle}>Min</Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Card sx={cardStyle}>
          <CardContent>
            <Typography sx={valueStyle}>
              {parseFloat(data.median.toFixed(2))}
            </Typography>
            <Typography sx={labelStyle}>Median</Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Card sx={cardStyle}>
          <CardContent>
            <Typography sx={valueStyle}>
              {parseFloat(data.max.toFixed(2))}
            </Typography>
            <Typography sx={labelStyle}>Max</Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

const FeatureCards = ({ data }) => {
  const theme = useTheme();

  const cardStyle = {
    minWidth: 200,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 2,
    boxShadow: 3,
    padding: 2,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };

  const labelStyle = {
    color: theme.palette.text.secondary,
    fontSize: "0.875rem",
    fontWeight: 600,
  };

  const valueStyle = {
    color: theme.palette.text.primary,
    fontSize: "1.5rem",
    fontWeight: 700,
  };

  return (
    <Grid container spacing={3} justifyContent="center">
      <Grid item xs={12} sm={6} md={4}>
        <Card sx={cardStyle}>
          <CardContent>
            <Typography sx={valueStyle}>
              {parseFloat(data?.activeUserCount?.toFixed(2) || 0)}
            </Typography>
            <Typography sx={labelStyle}>Active Users</Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Card sx={cardStyle}>
          <CardContent>
            <Typography sx={valueStyle}>
              {parseFloat(data?.activeUserProportion?.toFixed(2) || 0)}%
            </Typography>
            <Typography sx={labelStyle}>Active User Proportion</Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Card sx={cardStyle}>
          <CardContent>
            <Typography sx={valueStyle}>
              {parseFloat(data.inactiveUserCount.toFixed(2))}
            </Typography>
            <Typography sx={labelStyle}>Inactive Users</Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Card sx={cardStyle}>
          <CardContent>
            <Typography sx={valueStyle}>
              {parseFloat(data.activeFeatureCount.toFixed(2))}
            </Typography>
            <Typography sx={labelStyle}>Active Features</Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Card sx={cardStyle}>
          <CardContent>
            <Typography sx={valueStyle}>
              {parseFloat(data.activeFeatureProportion.toFixed(2))}%
            </Typography>
            <Typography sx={labelStyle}>Active Feature Proportion</Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Card sx={cardStyle}>
          <CardContent>
            <Typography sx={valueStyle}>
              {parseFloat(data.inactiveFeatureCount.toFixed(2))}
            </Typography>
            <Typography sx={labelStyle}>Inactive Features</Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Card sx={cardStyle}>
          <CardContent>
            <Typography sx={valueStyle}>
              {parseFloat(data.totalCount.toFixed(2))}
            </Typography>
            <Typography sx={labelStyle}>Total Activities</Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

const PaymentCards = ({ data }) => {
  const theme = useTheme();

  const cardStyle = {
    minWidth: 200,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 2,
    boxShadow: 3,
    padding: 2,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };

  const labelStyle = {
    color: theme.palette.text.secondary,
    fontSize: "0.875rem",
    fontWeight: 600,
  };

  const valueStyle = {
    color: theme.palette.text.primary,
    fontSize: "1.5rem",
    fontWeight: 700,
  };

  return (
    <Grid container spacing={3} justifyContent="center">
      <Grid item xs={6} sm={4} md={3}>
        <Card sx={cardStyle}>
          <CardContent>
            <Typography sx={valueStyle}>
              {parseFloat(data?.totalUsers?.toFixed(2) || 0)}
            </Typography>
            <Typography sx={labelStyle}>Total Users</Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={6} sm={4} md={3}>
        <Card sx={cardStyle}>
          <CardContent>
            <Typography sx={valueStyle}>
              {parseFloat(data?.maleUsers?.toFixed(2) || 0)}
            </Typography>
            <Typography sx={labelStyle}>Total Males</Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={6} sm={4} md={3}>
        <Card sx={cardStyle}>
          <CardContent>
            <Typography sx={valueStyle}>
              {parseFloat(data?.femaleUsers?.toFixed(2) || 0)}
            </Typography>
            <Typography sx={labelStyle}>Total Females</Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={6} sm={4} md={3}>
        <Card sx={cardStyle}>
          <CardContent>
            <Typography sx={valueStyle}>
              {parseFloat(data?.totalTierOneUsers?.toFixed(2) || 0)}
            </Typography>
            <Typography sx={labelStyle}>Tier One Users</Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={6} sm={4} md={3}>
        <Card sx={cardStyle}>
          <CardContent>
            <Typography sx={valueStyle}>
              {parseFloat(data?.tierOneProportion?.toFixed(2) || 0)}%
            </Typography>
            <Typography sx={labelStyle}>Tier One Proportion</Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={6} sm={4} md={3}>
        <Card sx={cardStyle}>
          <CardContent>
            <Typography sx={valueStyle}>
              {parseFloat(data?.tierOneMaleUsers?.toFixed(2) || 0)}
            </Typography>
            <Typography sx={labelStyle}>Tier One Males</Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={6} sm={4} md={3}>
        <Card sx={cardStyle}>
          <CardContent>
            <Typography sx={valueStyle}>
              {parseFloat(data?.tierOneFemaleUsers?.toFixed(2) || 0)}
            </Typography>
            <Typography sx={labelStyle}>Tier One Females</Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={6} sm={4} md={3}>
        <Card sx={cardStyle}>
          <CardContent>
            <Typography sx={valueStyle}>
              {parseFloat(data?.monthlyTierOneUsers?.toFixed(2) || 0)}
            </Typography>
            <Typography sx={labelStyle}>Monthly Tier One Users</Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={6} sm={4} md={3}>
        <Card sx={cardStyle}>
          <CardContent>
            <Typography sx={valueStyle}>
              {parseFloat(data?.annualTierOneUsers?.toFixed(2) || 0)}
            </Typography>
            <Typography sx={labelStyle}>Annual Tier One Users</Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={6} sm={4} md={3}>
        <Card sx={cardStyle}>
          <CardContent>
            <Typography sx={valueStyle}>
              {parseFloat(data?.totalTierTwoUsers?.toFixed(2) || 0)}
            </Typography>
            <Typography sx={labelStyle}>Tier Two Users</Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={6} sm={4} md={3}>
        <Card sx={cardStyle}>
          <CardContent>
            <Typography sx={valueStyle}>
              {parseFloat(data?.tierTwoProportion?.toFixed(2) || 0)}%
            </Typography>
            <Typography sx={labelStyle}>Tier Two Proportion</Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={6} sm={4} md={3}>
        <Card sx={cardStyle}>
          <CardContent>
            <Typography sx={valueStyle}>
              {parseFloat(data?.tierTwoMaleUsers?.toFixed(2) || 0)}
            </Typography>
            <Typography sx={labelStyle}>Tier Two Males</Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={6} sm={4} md={3}>
        <Card sx={cardStyle}>
          <CardContent>
            <Typography sx={valueStyle}>
              {parseFloat(data?.tierTwoFemaleUsers?.toFixed(2) || 0)}
            </Typography>
            <Typography sx={labelStyle}>Tier Two Females</Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={6} sm={4} md={3}>
        <Card sx={cardStyle}>
          <CardContent>
            <Typography sx={valueStyle}>
              {parseFloat(data?.monthlyTierTwoUsers?.toFixed(2) || 0)}
            </Typography>
            <Typography sx={labelStyle}>Monthly Tier Two Users</Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={6} sm={4} md={3}>
        <Card sx={cardStyle}>
          <CardContent>
            <Typography sx={valueStyle}>
              {parseFloat(data?.annualTierTwoUsers?.toFixed(2) || 0)}
            </Typography>
            <Typography sx={labelStyle}>Annual Tier Two Users</Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={6} sm={4} md={3}>
        <Card sx={cardStyle}>
          <CardContent>
            <Typography sx={valueStyle}>
              {parseFloat(data?.totalExpiredUsers?.toFixed(2) || 0)}
            </Typography>
            <Typography sx={labelStyle}>Expired Users</Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={6} sm={4} md={3}>
        <Card sx={cardStyle}>
          <CardContent>
            <Typography sx={valueStyle}>
              {parseFloat(data?.expiredProportion?.toFixed(2) || 0)}%
            </Typography>
            <Typography sx={labelStyle}>Expired Proportion</Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={6} sm={4} md={3}>
        <Card sx={cardStyle}>
          <CardContent>
            <Typography sx={valueStyle}>
              {parseFloat(data?.expiredMaleUsers?.toFixed(2) || 0)}
            </Typography>
            <Typography sx={labelStyle}>Expired Males</Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={6} sm={4} md={3}>
        <Card sx={cardStyle}>
          <CardContent>
            <Typography sx={valueStyle}>
              {parseFloat(data?.expiredFemaleUsers?.toFixed(2) || 0)}
            </Typography>
            <Typography sx={labelStyle}>Expired Females</Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export { RetentionCards, FeatureCards, PaymentCards };
