import React, { useState } from "react";
import axios from "../../api/axiosConfig";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Chip,
} from "@mui/material";
import { toast } from "react-toastify";
import Loader from "../Loader/Loader"; // Import your loader component
import MoreVertIcon from "@mui/icons-material/MoreVert";

const BlogCard = ({ blogItem, handleEditRowButton }) => {
  const [loading, setLoading] = useState(false); // State for loading
  const [anchorEl, setAnchorEl] = useState(null); // For the dropdown menu

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const deleteBlog = async (id) => {
    try {
      const isConfirmed = window.confirm(
        `Are you sure you want to delete this news sentiment?`
      );
      if (isConfirmed) {
        setLoading(true);
        const response = await axios.delete(`/news-notifs/delete-blog/${id}`);
        toast.success(response.data.status);
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.message || "Error deleting news sentiment"
      );
      console.error("Error deleting news sentiment:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && (
        <div className="overlay">
          <Loader />
        </div>
      )}
      <Box sx={{ position: "relative", maxWidth: 400, marginBottom: 2 }}>
        <Card>
          {/* Options Button */}
          <IconButton
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "greenyellow",
            }}
            onClick={handleMenuClick}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem
              onClick={() => {
                handleEditRowButton("blog", blogItem.id);
                handleMenuClose();
              }}
            >
              Edit
            </MenuItem>
            <MenuItem
              onClick={() => {
                deleteBlog(blogItem.id);
                handleMenuClose();
              }}
            >
              Delete
            </MenuItem>
          </Menu>

          {/* Cover Photo */}
          <CardMedia
            sx={{ height: 200, cursor: "pointer" }}
            image={blogItem.cover || "/news.png"}
            title={blogItem.name}
            onClick={() => window.open(blogItem.url, "_blank")}
          />

          {/* Category Chip */}
          <Chip
            label={blogItem.blog_category_blog_category.name}
            sx={{
              position: "absolute",
              top: 8,
              left: 8,
              backgroundColor: "white",
              borderRadius: 2,
            }}
          />

          {/* Card Content */}
          <CardContent>
            <Typography variant="body2" color="text.secondary">
              {blogItem.author_profile.name} ({blogItem.date})
            </Typography>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              onClick={() => window.open(blogItem.url, "_blank")}
              sx={{ cursor: "pointer" }}
            >
              {blogItem.name}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {blogItem.description}
            </Typography>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

export default BlogCard;
