import React from "react";
import ReactApexChart from "react-apexcharts";

const ClusteredBarChart = ({ data }) => {
  // Handle empty data gracefully
  const categories =
    data.length > 0 ? data.map((entry) => entry.page) : ["No Data"]; // Default category
  const allFeatures =
    data.length > 0
      ? [
          ...new Set(
            data.flatMap((entry) => entry.features.map((f) => f.feature))
          ),
        ]
      : []; // Default: no features

  // Create series with counts for each feature
  const series =
    allFeatures.length > 0
      ? allFeatures.map((feature) => ({
          name: feature,
          data: categories.map((page) => {
            const entry = data.find((d) => d.page === page);
            const featureData = entry?.features.find(
              (f) => f.feature === feature
            );
            return featureData ? featureData.count : 0;
          }),
        }))
      : [
          {
            name: "No Data",
            data: [0], // Default series
          },
        ];

  const options = {
    chart: {
      type: "bar",
      height: 350,
      toolbar: { show: false }, // No toolbar (like Chart.js)
      zoom: { enabled: false }, // Disable zooming if not needed
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        borderRadius: 5,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories, // X-axis categories
    },
    yaxis: {},
    grid: {
      show: false, // Hide grid lines
    },
    legend: {
      show: false, // Hide legend
    },
    colors: ["#4287f5"], // Set uniform color for all bars
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val;
        },
      },
    },
  };

  return (
    <div>
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={350}
      />
    </div>
  );
};

export default ClusteredBarChart;
