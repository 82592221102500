import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import realAxios from "axios";
import { fetchAuthSession } from "aws-amplify/auth";
import Loader from "../../components/Loader/Loader"; // Import your loader component
import {
  Box,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  TextField,
  Checkbox,
  ListItemText,
} from "@mui/material";
import DynamicTable from "../../components/MUI Table/DynamicTable";
import { PaymentCards } from "./components/DataCards";
import BarChart from "../../components/Charts/Bar Chart/BarChart";
import MultiLineChart from "./components/MultiLineChart";

function Payments() {
  const [loading, setLoading] = useState(false); // State for loading
  const [selectedDuration, setSelectedDuration] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedLineValues, setSelectedLineValues] = useState([]);
  const [lineData, setLineData] = useState([]);
  const [barData, setBarData] = useState([]);
  const [selectedTableValue, setSelectedTableValue] = useState("freeUsers"); // Default selected value
  const [cards, setCards] = useState({
    totalUsers: 0,
    maleUsers: 0,
    femaleUsers: 0,
    totalTierOneUsers: 0,
    tierOneProportion: 0,
    tierOneMaleUsers: 0,
    tierOneFemaleUsers: 0,
    monthlyTierOneUsers: 0,
    annualTierOneUsers: 0,
    totalTierTwoUsers: 0,
    tierTwoProportion: 0,
    tierTwoMaleUsers: 0,
    tierTwoFemaleUsers: 0,
    monthlyTierTwoUsers: 0,
    annualTierTwoUsers: 0,
    totalExpiredUsers: 0,
    expiredProportion: 0,
    expiredMaleUsers: 0,
    expiredFemaleUsers: 0,
  });
  const [tables, setTables] = useState({
    freeUsers: [],
    tierOneUsers: [],
    tierTwoUsers: [],
    expiredUsers: [],
  });

  const yAxis = {
    field: "count",
    name: "Count",
    show: false,
  };

  const xAxis = {
    field: "name",
    name: "Subscription",
    show: false,
  };

  // Options for the dropdown
  const lineOptions = [
    { value: "all", label: "All" },
    { value: "free", label: "Free" },
    { value: "tierOne", label: "Tier One" },
    { value: "tierTwo", label: "Tier Two" },
    { value: "expired", label: "Expired" },
  ];

  useEffect(() => {}, []);

  const fetchData = async (duration, startDate, endDate) => {
    try {
      setLoading(true);
      const authToken = (await fetchAuthSession()).tokens?.idToken?.toString();
      const response = await realAxios.post(
        "https://07prxr5v1m.execute-api.eu-west-2.amazonaws.com/prod/user-data-analytics/",
        {
          duration,
          startDate,
          endDate,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`, // Replace YOUR_AUTHORIZATION_CODE with the actual token
          },
        }
      );

      const { barData, lineData, cards, tables } = response.data.data;

      setCards(cards);
      setBarData(barData);
      setLineData(lineData);
      setTables(tables);
      setSelectedLineValues(["free", "tierOne", "tierTwo"]);
    } catch (error) {
      handleReset();
      console.error("Error fetching data: ", error);
      if (error.response?.data?.message)
        toast.error(error.response.data.message);
    } finally {
      setLoading(false); // Set loading to false after data is fetched
    }
  };

  const onSelectedDurationChange = (value) => {
    setSelectedDuration(value);

    if (value !== "custom") {
      fetchData(value, "", "");
    } else {
      setStartDate("");
      setEndDate("");
    }
  };

  const onStartDateChange = (value) => {
    setStartDate(value);
    if (endDate) {
      fetchData("custom", value, endDate);
    }
  };

  const onEndDateChange = (value) => {
    setEndDate(value);
    if (startDate) {
      fetchData("custom", startDate, value);
    }
  };

  const handleReset = () => {
    setSelectedDuration("");
    setStartDate("");
    setEndDate("");
  };

  // Handle table dropdown change
  const handleTableDropdownChange = (event) => {
    setSelectedTableValue(event.target.value);
  };

  // Handle dropdown change
  const handleLineValuesChange = (event) => {
    const value = event.target.value;

    if (value.includes("all")) {
      if (!selectedLineValues.includes("all")) {
        setSelectedLineValues(["all"]);
      } else {
        setSelectedLineValues(value.filter((item) => item !== "all"));
      }
    } else {
      setSelectedLineValues(value);
    }
  };

  return (
    <div>
      {loading && (
        <div className="overlay">
          <Loader />
        </div>
      )}
      {/* filter */}
      <Box style={{ marginTop: "20px" }}>
        <div className="card h-100">
          <div className="card-body">
            {/* duration */}
            <FormControl
              size="small"
              variant="outlined"
              style={{
                minWidth: 150,
                margin: "0 8px",
                height: "56px",
                marginTop: "8px",
              }}
            >
              <InputLabel id="duration">Select Duration</InputLabel>
              <Select
                labelId="page"
                value={selectedDuration || ""} // State to manage selected value
                onChange={(e) => onSelectedDurationChange(e.target.value)} // Handler to update the state
                label="page"
                style={{ height: "100%" }}
              >
                <MenuItem value="">Select Duration</MenuItem>
                <MenuItem value="day">Day</MenuItem>
                <MenuItem value="week">Week</MenuItem>
                <MenuItem value="month">Month</MenuItem>
                <MenuItem value="year">Year</MenuItem>
                <MenuItem value="all">All Time</MenuItem>
                <MenuItem value="custom">Custom</MenuItem>
              </Select>
            </FormControl>

            {/* duration === "custom" */}
            {selectedDuration === "custom" && (
              <div>
                {/* Start Date */}
                <FormControl
                  size="small"
                  variant="outlined"
                  style={{
                    minWidth: 150,
                    margin: "0 8px",
                    height: "56px",
                    marginTop: "8px",
                  }}
                >
                  <TextField
                    label="Start Date"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    value={startDate}
                    onChange={(e) => onStartDateChange(e.target.value)}
                    size="small"
                  />
                </FormControl>

                {/* End Date */}
                <FormControl
                  size="small"
                  variant="outlined"
                  style={{
                    minWidth: 150,
                    margin: "0 8px",
                    height: "56px",
                    marginTop: "8px",
                  }}
                >
                  <TextField
                    label="End Date"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    value={endDate}
                    onChange={(e) => onEndDateChange(e.target.value)}
                    size="small"
                  />
                </FormControl>
              </div>
            )}
          </div>
        </div>
      </Box>

      {/* line chart */}
      <Box style={{ marginTop: "20px" }}>
        <div className="card h-100">
          <div className="card-header d-flex justify-content-between align-items-center">
            <h4 className="mb-0">Registration Trend</h4>
            <FormControl
              variant="outlined"
              size="small"
              style={{ minWidth: 150 }}
            >
              <InputLabel id="multi-select-label">Select Metric</InputLabel>
              <Select
                labelId="multi-select-label"
                id="multi-select"
                multiple
                value={selectedLineValues}
                onChange={handleLineValuesChange}
                label="Filter"
                renderValue={(selected) =>
                  selected
                    .map(
                      (val) =>
                        lineOptions.find((opt) => opt.value === val).label
                    )
                    .join(", ")
                }
              >
                {lineOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    <Checkbox
                      checked={selectedLineValues.includes(option.value)}
                    />
                    <ListItemText primary={option.label} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="card-body">
            <MultiLineChart
              lineData={lineData}
              selectedLineValues={selectedLineValues}
            />
          </div>
        </div>
      </Box>

      {/* stats */}
      <Box style={{ marginTop: "20px" }}>
        <div className="row equal-height">
          <div className="col-md-12 mb-4">
            <div className="card h-100">
              <div className="card-body">
                <PaymentCards data={cards} />
              </div>
            </div>
          </div>
        </div>
      </Box>

      {/* tables */}
      <Box style={{ marginTop: "20px" }}>
        <div className="row equal-height">
          {/* bar chart*/}
          <div className="col-md-6 mb-4">
            <div className="card h-100">
              <div className="card-header">
                <h4 className="mb-0">Plan Comparison</h4>
              </div>
              <div className="card-body">
                <BarChart data={barData} yAxis={yAxis} xAxis={xAxis} />
              </div>
            </div>
          </div>

          {/* table*/}
          <div className="col-md-6 mb-4">
            <div className="card h-100">
              <div className="card-header d-flex justify-content-between align-items-center">
                <h4 className="mb-0">Data</h4>
                <FormControl
                  variant="outlined"
                  size="small"
                  style={{ minWidth: 120, maxHeight: 30 }}
                >
                  <InputLabel id="dropdown-label">Select Table</InputLabel>
                  <Select
                    labelId="dropdown-label"
                    id="dropdown"
                    value={selectedTableValue}
                    onChange={handleTableDropdownChange}
                    label="Filter"
                  >
                    <MenuItem value="freeUsers">Free</MenuItem>
                    <MenuItem value="tierOneUsers">Tier One</MenuItem>
                    <MenuItem value="tierTwoUsers">Tier Two</MenuItem>
                    <MenuItem value="expiredUsers">Expired</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="card-body">
                <DynamicTable data={tables[selectedTableValue]} />
              </div>
            </div>
          </div>
        </div>
      </Box>
      <ToastContainer />
    </div>
  );
}

export default Payments;
