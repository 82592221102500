import React, { useEffect, useState } from "react";
import axios from "../../api/axiosConfig";
import Loader from "../../components/Loader/Loader"; // Import your loader component
import InsertModal from "./../../components/Popups/InsertRow";
import { ToastContainer, toast } from "react-toastify";
// import DynamicTable from "../../components/MUI Table/DynamicTable";
// import SymbolSearch from "../../components/Popups/SymbolSearch";
import NewsCard from "../../components/News Card/News Card";
import {
  Box,
  Grid,
  Pagination,
  Select,
  MenuItem,
  Button,
  InputLabel,
  FormControl,
  Checkbox,
  ListItemText,
} from "@mui/material";
import EditModal from "../../components/Popups/EditRow";

function News() {
  const [loading, setLoading] = useState(false); // State for loading
  const [newsData, setNewsData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [symbols, setSymbols] = useState([]);
  const [selectedSymbols, setSelectedSymbols] = useState([]);
  const [formData, setFormData] = useState({}); // State for form data
  const [showInsertModal, setShowInsertModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedTable, setselectedTable] = useState("");
  const [newsID, setnewsID] = useState("");
  const [rowID, setrowID] = useState("");

  // Fetch distinct symbols in useEffect
  useEffect(() => {
    const fetchSymbols = async () => {
      try {
        const response = await axios.get("/news-notifs/get-news-symbols/");
        setSymbols(response.data.data);
      } catch (error) {
        toast.error(
          error?.response?.data?.message || "Error fetching news symbols"
        );
        console.error("Error fetching symbols:", error);
      }
    };

    fetchSymbols();
  }, []);

  const fetchNewsData = async (value) => {
    try {
      const response = await axios.post("/news-notifs/fetch-news/", {
        page: value,
        limit: 10,
        symbols: selectedSymbols, // Pass selected symbols here (array)
      });
      setNewsData(response.data.data);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error("Error fetching news data:", error);
      toast.error(error?.response?.data?.message || "Error fetching news");
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
    fetchNewsData(value);
  };

  const handleSymbolChange = (event) => {
    const value = event.target.value;

    if (value.includes("all")) {
      setSelectedSymbols([]); // Clear when "All" is selected
    } else {
      setSelectedSymbols(value);
    }
  };

  // Update handleInsertClick to set columns
  const handleInsertRowButton = async (table) => {
    setselectedTable(table);
    setFormData({});
    setShowInsertModal(true);
  };

  // Update handleInsertClick to set columns
  const handleEditRowButton = async (table, id) => {
    setrowID(id);
    setselectedTable(table);
    setFormData({});
    setShowEditModal(true);
  };

  const handleEditModalClose = () => {
    setShowEditModal(false);
    setselectedTable("");
    setnewsID("");
    setrowID("");
  };

  const handleInsertModalClose = () => {
    setShowInsertModal(false);
    setselectedTable("");
    setnewsID("");
  };

  const handleInputChange = (column, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [column]: value,
    }));
  };

  const handleInsertRow = async () => {
    try {
      setLoading(true);
      if (
        Object.keys(formData).length === 0 ||
        Object.values(formData).every((value) => value === "")
      ) {
        return toast.error(`You cannot send an empty data`);
      }
      if (
        (selectedTable === "news_symbol" || selectedTable === "news_group") &&
        !formData.news
      ) {
        formData.news = newsID; // If it's not already set, assign newsID
      }

      if (rowID && rowID !== "") {
        formData.id = rowID;
      }

      const form = new FormData();
      form.append("selectedTable", selectedTable);
      Object.keys(formData).forEach((key) => {
        form.append(`data[${key}]`, formData[key]);
      });

      const response = await axios.post(
        "/insert/insert-single-without-symbol",
        form,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      if (response.status === 200) {
        toast.success("Data inserted successfully");
      } else {
        toast.error(`Unexpected response code: ${response.status}`);
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "Error inserting row");
      console.error("Error inserting data", error);
    } finally {
      setShowInsertModal(false);
      setLoading(false);
      setselectedTable("");
      setnewsID("");
    }
  };

  const handleEditRow = async () => {
    try {
      setLoading(true);
      if (
        Object.keys(formData).length === 0 ||
        Object.values(formData).every((value) => value === "")
      ) {
        return toast.error(`You cannot send an empty data`);
      }

      const form = new FormData();
      Object.keys(formData).forEach((key) => {
        form.append(`data[${key}]`, formData[key]);
      });

      const response = await axios.patch("/insert/edit-row", form, {
        headers: { "Content-Type": "multipart/form-data" },
        params: { id: rowID, selectedTable },
      });
      if (response.status === 200) {
        toast.success("Row edited successfully");
      } else {
        toast.error(`Unexpected response code: ${response.status}`);
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "Error editing row");
      console.error("Error editing row", error);
    } finally {
      setShowEditModal(false);
      setLoading(false);
      setselectedTable("");
      setrowID("");
    }
  };

  return (
    <>
      {loading && (
        <div className="overlay">
          <Loader />
        </div>
      )}
      <Box sx={{ marginBottom: "20px" }}>
        <div className="card h-100">
          <div className="card-header">
            <h4 className="mb-0">Filters</h4>
          </div>
          <div className="card-body">
            <Box sx={{ width: "300px" }}>
              <FormControl fullWidth>
                <InputLabel>Select Symbols</InputLabel>
                <Select
                  multiple
                  value={selectedSymbols}
                  onChange={handleSymbolChange}
                  renderValue={(selected) =>
                    selected
                      .map((symbolId) => {
                        const symbol = symbols.find(
                          (sym) => sym.id === symbolId
                        );
                        return symbol ? symbol.name : "";
                      })
                      .join(", ")
                  }
                >
                  <MenuItem value="all">
                    <em>All</em>
                  </MenuItem>
                  {symbols.map((symbol) => (
                    <MenuItem key={symbol.id} value={symbol.id}>
                      <Checkbox
                        checked={selectedSymbols.indexOf(symbol.id) > -1}
                      />
                      <ListItemText primary={symbol.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <Button
              variant="contained"
              onClick={() => fetchNewsData(1)}
              sx={{ backgroundColor: "black", color: "white" }}
            >
              Fetch
            </Button>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "black",
                color: "white",
                marginLeft: "20px",
              }}
              onClick={() => handleInsertRowButton("special_news")}
            >
              Add News
            </Button>
          </div>
        </div>
      </Box>

      {/* News Cards Box */}
      <div className="row equal-height">
        <div className="col-md-12 mb-4">
          <div className="card h-100">
            <div className="card-header">
              <h4 className="mb-0">News</h4>
            </div>
            <div className="card-body">
              <Pagination
                count={totalPages}
                page={page}
                onChange={handlePageChange}
                sx={{
                  marginTop: "20px",
                  marginBottom: "20px",
                  display: "flex",
                  justifyContent: "center",
                }}
              />
              <Grid
                container
                spacing={2}
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "16px",
                  justifyContent: "space-between",
                }}
              >
                {newsData.map((newsItem) => (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={newsItem.id}>
                    <NewsCard
                      newsItem={newsItem}
                      handleInsertRowButton={handleInsertRowButton}
                      setnewsID={setnewsID}
                      handleEditRowButton={handleEditRowButton}
                    />
                  </Grid>
                ))}
              </Grid>
              <Pagination
                count={totalPages}
                page={page}
                onChange={handlePageChange}
                sx={{
                  marginTop: "20px",
                  display: "flex",
                  justifyContent: "center",
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {/* Insert Modal Popup */}
      <div>
        <InsertModal
          show={showInsertModal}
          handleClose={handleInsertModalClose}
          tableTitle={selectedTable}
          handleInputChange={handleInputChange}
          handleInsertRow={handleInsertRow}
          foreignID={newsID}
        />
      </div>
      {/* Edit Modal Popup */}
      <div>
        <EditModal
          show={showEditModal}
          id={rowID}
          handleClose={handleEditModalClose}
          tableTitle={selectedTable}
          handleInputChange={handleInputChange}
          handleEditRow={handleEditRow}
          foreignID={newsID}
        />
      </div>
      <ToastContainer />
    </>
  );
}

export default News;
