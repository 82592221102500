import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { useTheme } from "@mui/material";

const calculateBoxPlotStatistics = (data) => {
  const sortedData = [...data].sort((a, b) => a - b);

  const min = sortedData[0];
  const max = sortedData[sortedData.length - 1];
  const median = calculateMedian(sortedData);
  const q1 =
    calculateMedian(sortedData.slice(0, Math.floor(sortedData.length / 2))) ||
    (min + median) / 2;
  const q3 =
    calculateMedian(sortedData.slice(Math.ceil(sortedData.length / 2))) ||
    (max + median) / 2;

  return [min, q1, median, q3, max];
};

const calculateMedian = (arr) => {
  const len = arr.length;
  const middle = Math.floor(len / 2);

  if (len % 2 === 0) {
    return (arr[middle - 1] + arr[middle]) / 2;
  } else {
    return arr[middle];
  }
};

const BoxPlotChart = ({ data, yAxisRange }) => {
  const theme = useTheme();
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  // Prepare and sort the data
  const boxPlotData = data
    .map((item) => ({
      x: item.label,
      y: item.data.sort((a, b) => a - b),
    }))
    .map((item) => ({
      x: item.x,
      y: calculateBoxPlotStatistics(item.y),
    }));

  // Determine the y-axis range dynamically if not provided
  // const calculatedYRange = {
  //   min: Math.min(...boxPlotData.flatMap((item) => item.y)),
  //   max: Math.max(...boxPlotData.flatMap((item) => item.y)),
  // };

  // const yAxis = yAxisRange
  //   ? { min: yAxisRange[0], max: yAxisRange[1] }
  //   : calculatedYRange;

  const chartOptions = {
    chart: {
      type: "boxPlot",
      height: 350,
      toolbar: { show: false }, // No toolbar (like Chart.js)
      zoom: { enabled: false }, // Disable zooming if not needed
    },
    tooltip: {
      theme: "dark",
    },
    xaxis: {
      labels: {
        style: {
          colors: theme.palette.text.primary,
          fontSize: "14px",
          fontWeight: "bold",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: theme.palette.text.primary,
          fontSize: "14px",
          fontWeight: "bold",
        },
        formatter: (value) => {
          return Math.round(value); // Round the value to the nearest integer (0 decimal places)
        },
      },
    },
    grid: {
      borderColor: "white",
    },
  };

  const chartData = {
    series: [{ type: "boxPlot", data: boxPlotData }],
  };

  if (!isMounted) return null;

  return (
    <div>
      <div id="chart">
        <Chart
          key={data.length}
          options={chartOptions}
          series={chartData.series}
          type="boxPlot"
          height={350}
        />
        <div id="html-dist"></div>
      </div>
    </div>
  );
};

export default BoxPlotChart;
