import React, { useEffect, useState } from "react";
import { Button, Card, CardContent, Typography } from "@mui/material";
import axios from "../../api/axiosConfig";
import Loader from "../../components/Loader/Loader"; // Import your loader component
import InsertModal from "./../../components/Popups/InsertRow";
import { ToastContainer, toast } from "react-toastify";
import { Modal, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  columnsMap,
  excludeBulk,
  tables,
} from "../../components/Models/tablesAndColumns";
import DynamicTable from "../../components/MUI Table/DynamicTable";
import { SingleSymbolSearch } from "../../components/Popups/SymbolSearch";
import * as XLSX from "xlsx";
import { fetchDistinctNames } from "../../components/Models/distinctQuery";
import EditModal from "../../components/Popups/EditRow";
import "./InsertandEdits.css"; // Assuming you have a CSS file for styling

function InsertandEdits() {
  const [loading, setLoading] = useState(true); // State for loading
  const [tableData, setTableData] = useState([]);
  const [activeTable, setActiveTable] = useState(null);
  const [viewEdit, setviewEdit] = useState(null);
  const [showInsertModal, setShowInsertModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [formData, setFormData] = useState({}); // State for form data
  const [show, setShow] = useState(false);
  const [viewMode, setViewMode] = useState("");
  const [minRows, setMinRows] = useState("");
  const [maxRows, setMaxRows] = useState("");
  const [selectedColumn, setSelectedColumn] = useState("");
  const [columnValue, setcolumnValue] = useState("");
  const [columns, setcolumns] = useState([]);
  const [selectedTable, setselectedTable] = useState(null);
  const [viewData, setviewData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showBulkModal, setshowBulkModal] = useState(false);
  const [showUploadButton, setShowUploadButton] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [rowID, setrowID] = useState("");
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [imageName, setImageName] = useState("");
  const [generatedImageURL, setGeneratedImageURL] = useState(null);

  const fetchData = async () => {
    setLoading(true);
    // Fetch data from the API
    axios
      .get("/insert/get-table-counts")
      .then((response) => {
        setTableData(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        toast.error(error.response?.data?.message || "Error getting tables");
        console.error("Error fetching table counts:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleInsertClick = (table) => {
    setviewEdit(null);
    setActiveTable(activeTable === table ? null : table);
  };

  // Update handleInsertClick to set columns
  const handleInsertRowButton = async () => {
    setFormData({});
    setShowInsertModal(true);
    setcolumnValue("");
  };

  const handleInsertModalClose = () => {
    setShowInsertModal(false);
    setSelectedFile(null);
    setActiveTable(null);
  };

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      const fileType = e.target.files[0].type;
      const validImageTypes = ["image/jpeg", "image/png"];

      if (!validImageTypes.includes(fileType)) {
        toast.error("Please upload a valid image file (JPEG or PNG).");
        setSelectedFile(null);
        return;
      }
    }
    setSelectedFile(e.target.files[0]);
  };

  const handleInputChange = (column, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [column]: value,
    }));
    setcolumnValue(value);
  };

  const handleInsertRow = async () => {
    try {
      setLoading(true);
      // Ensure at least one valid form field or the selectedFile exists
      const hasValidData =
        (Object.keys(formData).length > 0 &&
          Object.values(formData).some((value) => value !== "")) ||
        selectedFile;

      if (!hasValidData) {
        return toast.error(`You cannot send an empty data`);
      }

      const form = new FormData();
      form.append("selectedTable", activeTable);
      Object.keys(formData).forEach((key) => {
        form.append(`data[${key}]`, formData[key]);
      });

      // Handle file input separately
      if (selectedFile && selectedFile instanceof File) {
        form.append("file", selectedFile);
      }

      const response = await axios.post(
        "/insert/insert-single-without-symbol",
        form,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      if (response.status === 200) {
        toast.success("Data inserted successfully");

        await fetchData();
      } else {
        toast.error(`Unexpected response code: ${response.status}`);
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "Error inserting row");
      console.error("Error inserting data", error);
    } finally {
      setShowInsertModal(false);
      setSelectedFile(null);
      setLoading(false);
      setActiveTable(null);
    }
  };

  const handleBulkClick = (table) => {
    // Handle the view more action here
    setselectedTable(table);
    setshowBulkModal(true);
  };

  const handleViewClick = (table) => {
    // Handle the view more action here
    setviewEdit(viewEdit === table ? null : table);
    setselectedTable(table);
    setcolumns(columnsMap[table]);
    setShow(true);
  };

  const handleClose = () => {
    // Handle the view more action here
    setShow(false);
    setActiveTable(null);
    setSelectedColumn("");
    setcolumnValue("");
    setViewMode("");
  };

  const handleFetch = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/insert/fetch-selected-table`, {
        params: {
          selectedTable,
          selectedColumn,
          columnValue,
          viewMode,
          minRow: minRows,
          maxRow: maxRows,
        },
      });
      if (response.status === 200) {
        setviewData(response.data.data);
        setShowModal(true);
      } else {
        toast.error(`Unexpected response code: ${response.status}`);
      }
    } catch (error) {
      if (error.response?.data?.message)
        toast.error(error.response.data.message);
      console.error("Error fetching data", error);
    } finally {
      setLoading(false);
      setSelectedColumn("");
      setcolumnValue("");
      setMinRows("");
      setMaxRows("");
      setViewMode("");
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setviewData([]);
  };

  const handleDownloadTemplate = async () => {
    if (selectedTable && columnsMap[selectedTable]) {
      const columns = columnsMap[selectedTable];
      const workbook = XLSX.utils.book_new();

      // Create the main sheet for the selected table
      const mainWorksheet = XLSX.utils.aoa_to_sheet([columns]);
      XLSX.utils.book_append_sheet(workbook, mainWorksheet, selectedTable);

      // Loop through columns and create additional sheets for symbol or tables columns
      for (const column of columns) {
        if (tables.includes(column) || column === "symbol") {
          setLoading(true);
          try {
            const data = await fetchDistinctNames(column); // Fetch distinct names

            if (data?.data) {
              // Extract id and name fields from the data
              const sheetData = [
                ["id", "name"],
                ...data.data.map((item) => [item.id, item.name]),
              ];

              // Create a new sheet and append it to the workbook
              const worksheet = XLSX.utils.aoa_to_sheet(sheetData);
              XLSX.utils.book_append_sheet(workbook, worksheet, column);
            }
          } catch (error) {
            toast.error(error.response?.data?.message || `Error fetching data`);
            console.error(
              `Error fetching distinct names for column ${column}:`,
              error
            );
          } finally {
            setLoading(false);
          }
        }
      }

      // Generate and download the Excel file
      XLSX.writeFile(workbook, `${selectedTable}_template.xlsx`);
    } else {
      toast.warn(`${selectedTable} not ready for bulk insert yet`);
    }
  };

  const handleUploadData = () => {
    setShowUploadButton(false);
    setSelectedFile(null);
    document.getElementById("fileInput").click(); // Trigger file input click
  };

  const handleCloseBulkModal = () => {
    setShowUploadButton(false);
    setselectedTable(null);
    setshowBulkModal(false);
    setSelectedFile(null);
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (
      file &&
      (file.type === "application/vnd.ms-excel" ||
        file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
    ) {
      setSelectedFile(file);
      setShowUploadButton(true); // Show upload button after valid file is selected
    } else {
      alert("Please select a valid Excel file (.xlsx or .xls).");
    }
    // Reset the file input value
    event.target.value = null;
  };

  const handleConfirmUpload = async () => {
    try {
      const confirmed = window.confirm(
        `Do you want to insert into ${selectedTable} in bulk?`
      );
      if (confirmed) {
        setLoading(true);
        const form = new FormData();
        form.append("selectedTable", selectedTable);

        // Handle file input separately
        if (selectedFile && selectedFile instanceof File) {
          form.append("file", selectedFile);
        }

        console.log(selectedTable);

        // Send the formData to the server using Axios
        const response = await axios.post("/insert/insert-bulk", form, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        toast.success(response.data.message);
      }
    } catch (error) {
      toast.error(error.response?.data?.message || `Error uploading file`);
      console.error(error);
    } finally {
      setSelectedFile(null);
      setShowUploadButton(false);
      setLoading(false);
    }
  };

  // Update handleInsertClick to set columns
  const handleEditRowButton = async (table, id) => {
    setrowID(id);
    setselectedTable(table);
    setFormData({});
    setShowEditModal(true);
  };

  const handleEditModalClose = () => {
    setShowEditModal(false);
    setSelectedFile(null);
    setrowID("");
  };

  const handleEditRow = async () => {
    try {
      setLoading(true);
      const hasValidData =
        (Object.keys(formData).length > 0 &&
          Object.values(formData).some((value) => value !== "")) ||
        selectedFile;

      if (!hasValidData) {
        return toast.error(`You cannot send an empty data`);
      }

      const form = new FormData();
      Object.keys(formData).forEach((key) => {
        form.append(`data[${key}]`, formData[key]);
      });

      // Handle file input separately
      if (selectedFile && selectedFile instanceof File) {
        form.append("file", selectedFile);
      }

      const response = await axios.patch("/insert/edit-row", form, {
        headers: { "Content-Type": "multipart/form-data" },
        params: { id: rowID, selectedTable },
      });
      if (response.status === 200) {
        toast.success("Row edited successfully");
      } else {
        toast.error(`Unexpected response code: ${response.status}`);
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "Error editing row");
      console.error("Error editing row", error);
    } finally {
      setShowEditModal(false);
      setLoading(false);
      setselectedTable("");
      setrowID("");
      setShow(false);
      setShowModal(false);
    }
  };

  const handleImageSubmit = async (e) => {
    e.preventDefault();
    const form = new FormData();

    if (imageName) form.append("name", imageName);
    if (selectedFile && selectedFile instanceof File) {
      form.append("file", selectedFile);
    }

    try {
      const response = await axios.post("/insert/generate-image-url", form, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      setGeneratedImageURL(response.data.icon);
      setIsImageModalOpen(true);
    } catch (error) {
      console.error("Error uploading file:", error);
    } finally {
      setImageName("");
      setSelectedFile(null);
    }
  };

  const handleImageModalClose = () => {
    setIsImageModalOpen(false);
    setGeneratedImageURL(null);
  };

  const handleImageModalShow = () => {
    setIsImageModalOpen(true);
  };

  return (
    <div className="container mt-5">
      {loading && (
        <div className="overlay">
          <Loader />
        </div>
      )}
      <h1>Insert and Edits</h1>
      <div className="row">
        {tableData.map((table) => (
          <div
            className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4"
            key={table.table}
          >
            <Card
              style={{
                border: "1px solid black",
                borderRadius: "8px",
              }}
            >
              <CardContent>
                <Typography variant="h6" component="div" gutterBottom>
                  {table.table}
                </Typography>
                <Typography variant="body2" color="black">
                  Count of rows: {table.count}
                </Typography>
              </CardContent>
              <>
                <CardContent>
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "#333",
                      color: "#fff",
                      marginRight: "4px",
                      marginLeft: "4px",
                    }}
                    onClick={() => handleViewClick(table.table)}
                  >
                    View/Edit
                  </Button>
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "#333",
                      color: "#fff",
                      marginRight: "4px",
                      marginLeft: "4px",
                    }}
                    onClick={() => handleInsertClick(table.table)}
                  >
                    Insert
                  </Button>
                </CardContent>
              </>
              {activeTable === table.table && (
                <>
                  <CardContent>
                    {!excludeBulk.includes(activeTable) && (
                      <>
                        <Button
                          variant="contained"
                          style={{
                            backgroundColor: "#333",
                            color: "#fff",
                            marginRight: "4px",
                            marginLeft: "4px",
                          }}
                          onClick={() => handleBulkClick(table.table)}
                        >
                          Bulk
                        </Button>
                      </>
                    )}

                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: "#333",
                        color: "#fff",
                        marginRight: "4px",
                        marginLeft: "4px",
                      }}
                      onClick={handleInsertRowButton}
                    >
                      Single
                    </Button>
                  </CardContent>
                </>
              )}
            </Card>
          </div>
        ))}
      </div>

      {/* button */}
      {tableData.length && (
        <div className="imageApp">
          <div className="box">
            <p>Generate Image URL</p>
            <Button
              onClick={handleImageModalShow}
              variant="contained"
              style={{
                backgroundColor: "#333",
                color: "#fff",
                marginRight: "4px",
                marginLeft: "4px",
              }}
            >
              Upload Image
            </Button>
          </div>

          <Modal
            show={isImageModalOpen}
            onHide={handleImageModalClose}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {generatedImageURL ? "Generated Image URL" : "Upload Image"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {generatedImageURL ? (
                <>
                  <img
                    src={generatedImageURL}
                    alt="Generated"
                    className="img-fluid mb-3"
                  />
                  <p className="text-break">{generatedImageURL}</p>
                </>
              ) : (
                <Form onSubmit={handleImageSubmit}>
                  <Form.Group className="mb-3">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      value={imageName}
                      onChange={(e) => setImageName(e.target.value)}
                      required
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>File</Form.Label>
                    <Form.Control
                      type="file"
                      onChange={(e) => setSelectedFile(e.target.files[0])}
                      required
                    />
                  </Form.Group>
                  <Button
                    type="submit"
                    variant="contained"
                    style={{
                      backgroundColor: "#333",
                      color: "#fff",
                      marginRight: "4px",
                      marginLeft: "4px",
                    }}
                  >
                    Submit
                  </Button>
                </Form>
              )}
            </Modal.Body>
          </Modal>
        </div>
      )}

      {/* Insert Modal Popup */}
      <div>
        <InsertModal
          show={showInsertModal}
          handleClose={handleInsertModalClose}
          tableTitle={activeTable}
          handleFileChange={handleFileChange}
          handleInputChange={handleInputChange}
          handleInsertRow={handleInsertRow}
        />
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>View {selectedTable}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Button
            variant="contained"
            style={{
              backgroundColor: "#333",
              color: "#fff",
              marginRight: "4px",
              marginLeft: "4px",
              marginBottom: "8px",
            }}
            onClick={() => {
              setViewMode("");
              handleFetch();
            }}
          >
            Top 50
          </Button>
          <Button
            variant="contained"
            style={{
              backgroundColor: "#333",
              color: "#fff",
              marginRight: "4px",
              marginLeft: "4px",
              marginBottom: "8px",
            }}
            onClick={() => {
              setMinRows("");
              setMaxRows("");
              setViewMode(viewMode === "limitRows" ? "" : "limitRows");
            }}
          >
            Limit Rows
          </Button>
          <Button
            variant="contained"
            style={{
              backgroundColor: "#333",
              color: "#fff",
              marginRight: "4px",
              marginLeft: "4px",
              marginBottom: "8px",
            }}
            onClick={() => {
              setSelectedColumn("");
              setViewMode(viewMode === "columnSearch" ? "" : "columnSearch");
            }}
          >
            Column Search
          </Button>

          {viewMode === "limitRows" && (
            <>
              <Form.Group>
                <Form.Label>First Row ID</Form.Label>
                <Form.Control
                  type="number"
                  value={minRows}
                  onChange={(e) => setMinRows(e.target.value)}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Last Row ID</Form.Label>
                <Form.Control
                  type="number"
                  value={maxRows}
                  onChange={(e) => setMaxRows(e.target.value)}
                />
              </Form.Group>
              <Button
                variant="contained"
                style={{
                  backgroundColor:
                    (!maxRows && !minRows) || minRows * 1 > maxRows * 1
                      ? "#ccc"
                      : "#333",
                  color: "#fff",
                  marginRight: "4px",
                  marginLeft: "4px",
                  marginTop: "10px",
                }}
                onClick={handleFetch}
                disabled={(!maxRows && !minRows) || minRows * 1 > maxRows * 1}
              >
                Fetch
              </Button>
            </>
          )}

          {viewMode === "columnSearch" && (
            <>
              <Form.Group>
                <Form.Control
                  as="select"
                  value={selectedColumn}
                  onChange={(e) => setSelectedColumn(e.target.value)}
                >
                  <option value="">Select Column</option>
                  {columns.map((col, index) => (
                    <option key={index} value={col}>
                      {col}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              {selectedColumn && (
                <Form.Group>
                  {selectedColumn === "symbol" ? (
                    <SingleSymbolSearch
                      column={selectedColumn}
                      handleInputChange={handleInputChange}
                    />
                  ) : (
                    <Form.Control
                      style={{ marginTop: "10px" }}
                      type="text"
                      placeholder={`Enter value for ${selectedColumn}`}
                      value={columnValue}
                      onChange={(e) => setcolumnValue(e.target.value)}
                    />
                  )}
                </Form.Group>
              )}
              {selectedColumn && columnValue && (
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "#333",
                    color: "#fff",
                    marginRight: "4px",
                    marginLeft: "4px",
                    marginTop: "10px",
                  }}
                  onClick={handleFetch}
                >
                  Fetch
                </Button>
              )}
            </>
          )}
        </Modal.Body>
      </Modal>
      {/* Modal Popup for Symbols */}
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        dialogClassName="custom-modal"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="table-container">
            <DynamicTable
              className="dynamic-table"
              data={viewData}
              handleEditRowButton={handleEditRowButton}
              selectedTable={selectedTable}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Bulk Modal */}
      <Modal show={showBulkModal} onHide={handleCloseBulkModal}>
        <Modal.Header closeButton>
          <Modal.Title>Bulk Insert Into {selectedTable}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Typography>
            Download the template for {selectedTable} if you do not have it
            already. After populating the template with correct data, upload it
            into the system. Note to use only an updated template.
            <br />
            <br />
            If the template has multiple sheets (pages), check for the column
            name matching the name of the sheet and only populate that column
            with the id of the associated name.
            <br />
            <br />
            For example, if the column name is "symbol", check the "symbol"
            sheet (page) and search for the value in the "id" column in the same
            row with the name of the symbol you are looking for in the "symbol"
            column.
            <br />
            <br />
            <b>
              Only include the correct id into each column that has a sheet
              named after it. Also, please do not change the name of any sheet.
            </b>
          </Typography>
          <div style={{ marginTop: "20px", display: "flex", gap: "10px" }}>
            <Button
              variant="contained"
              color="primary"
              style={{ backgroundColor: "#333", color: "#fff" }}
              onClick={handleDownloadTemplate}
            >
              Download Template
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{ backgroundColor: "#333", color: "#fff" }}
              onClick={handleUploadData}
            >
              Upload Data
            </Button>
            {/* Hidden file input */}
            <input
              id="fileInput"
              type="file"
              accept=".xlsx, .xls"
              style={{ display: "none" }}
              onChange={handleFileSelect}
            />

            {/* Conditionally show the Upload button */}
            {showUploadButton && (
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#000",
                  color: "#fff",
                  marginTop: "10px",
                }}
                onClick={handleConfirmUpload}
              >
                Upload
              </Button>
            )}

            {/* Display selected file */}
            {selectedFile && (
              <Typography style={{ marginTop: "10px" }}>
                Selected file: {selectedFile.name}
              </Typography>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="contained"
            onClick={handleCloseBulkModal}
            style={{ backgroundColor: "#333", color: "#fff" }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Edit Modal Popup */}
      <div>
        <EditModal
          show={showEditModal}
          id={rowID}
          handleClose={handleEditModalClose}
          tableTitle={selectedTable}
          handleFileChange={handleFileChange}
          handleInputChange={handleInputChange}
          handleEditRow={handleEditRow}
        />
      </div>
      <ToastContainer />
    </div>
  );
}

export default InsertandEdits;
